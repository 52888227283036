import { useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import currency from 'currency.js';
import { DateTime } from 'luxon';

import { useAppSelector } from 'src/app/store';
import { CurrencyInput } from 'src/common/CurrencyInput';
import { DataGrid } from 'src/common/DataGrid';
import type {
  InvoiceNestedLineItem,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { useLazyTripsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { TextInput } from 'src/common/primitives/TextInput';
import { removeUnitedStatesFromAddress } from 'src/common/util/removeUnitedStatesFromAddress';
import { serviceTypeOptionsMap } from 'src/features/add-trip/ServiceDetails/serviceTypeOptions';
import { transportTypeOptionsMap } from 'src/features/add-trip/ServiceDetails/transportTypeOptions';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

interface HydratedTripLineItem extends Omit<InvoiceNestedLineItem, 'trip'> {
  trip: TripRead;
}

interface InvoiceLineItemListProps {
  lineItems: InvoiceNestedLineItem[];
  onUpdateLineItem: (rowIndex: number, update: InvoiceNestedLineItem) => void;
  onDeleteLineItem: (rowIndex: number, row: InvoiceNestedLineItem) => void;
  onDoneLoading?: (tripId: string) => void;
}

const columnHelper = createColumnHelper<HydratedTripLineItem>();

function formatInvoiceDate(date: string): string {
  return `${DateTime.fromISO(date).toLocaleString(
    DateTime.DATE_MED
  )} at ${DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)}`;
}

// Creates a wrapper to align cells vertically
function CellAligner({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <div className="text-xs">&nbsp;</div>
      {children}
    </div>
  );
}

const columns = [
  columnHelper.display({
    header: 'Passenger',
    cell({ row }) {
      const lineItem = row.original;
      let display = '';

      if (lineItem.line_item_type === 'trip') {
        const trip = lineItem.trip;
        display = trip.passenger.full_name ?? 'Unknown Passenger';
      }

      return (
        <CellAligner>
          <div className="text-gray-800">{display}</div>
        </CellAligner>
      );
    },
  }),
  columnHelper.display({
    header: 'Items',
    cell({
      row,
      table: {
        options: { meta },
      },
    }) {
      const lineItem = row.original;
      if (lineItem.line_item_type !== 'trip') {
        if (meta?.isEditingRow?.(lineItem)) {
          return (
            <TextInput
              type="text"
              value={lineItem.description}
              placeholder="Enter item description"
              onChange={(e) => {
                meta.updateData?.(row.index, 'description', e.target.value);
              }}
            />
          );
        }

        return lineItem.description;
      }
      const trip = lineItem.trip;

      return (
        <>
          <div className="grid grid-cols-4 gap-y-2">
            <div className="pr-2">
              <div className="text-xs text-gray-500">
                Scheduled Date of Trip
              </div>
              <div className="whitespace-normal text-gray-800">
                {formatInvoiceDate(trip.scheduled_pickup_at)}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">From</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="pickup_address"
                data-raw-address={removeUnitedStatesFromAddress(
                  trip.pickup.address
                )}
              >
                {formatAddress(trip.pickup.address, '', false)}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">To</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="dropoff_address"
                data-raw-address={removeUnitedStatesFromAddress(
                  trip.dropoff.address
                )}
              >
                {formatAddress(trip.dropoff.address, '', false)}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Miles</div>
              <div className="text-gray-800">
                {trip.estimated_distance_miles?.toFixed(2)}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Code</div>
              <div className="whitespace-normal text-gray-800">
                {trip.confirmation_code}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Payer</div>
              <div className="whitespace-normal text-gray-800">
                {trip.payer.display_name}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">External Trip ID</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="external_trip_id"
              >
                {trip.external_trip_id ? trip.external_trip_id : <>&mdash;</>}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Passenger DOB</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="passenger_dob"
              >
                {trip.passenger.dob ? trip.passenger.dob : <>&mdash;</>}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Space Type</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="space_type"
              >
                {transportTypeOptionsMap[trip.space_type]}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Service Type</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="transport_type"
              >
                {serviceTypeOptionsMap[trip.service_type]}
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Status</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="status"
              >
                <TripStatusBadge status={trip.status} />
              </div>
            </div>
            <div>
              <div className="text-xs text-gray-500">Completion Time</div>
              <div
                className="whitespace-normal text-gray-800"
                data-testid="completion_time"
              >
                {trip.status === 'completed' && trip.completion_time ? (
                  formatInvoiceDate(trip.completion_time)
                ) : (
                  <>&mdash;</>
                )}
              </div>
            </div>
          </div>
        </>
      );
    },
  }),
  columnHelper.accessor('amount_cents', {
    header: 'Price',
    cell({
      getValue,
      row,
      table: {
        options: { meta },
      },
    }) {
      const price = getValue();
      const lineItem = row.original;
      const priceCellValue = (
        <div className="text-right font-mono text-gray-800">
          {currencyFormatter(price / 100)}
        </div>
      );

      // Apply cell aligner when the line item isnt a trip
      const displayCell =
        lineItem.line_item_type !== 'trip' ? (
          priceCellValue
        ) : (
          <CellAligner>{priceCellValue}</CellAligner>
        );

      if (lineItem.line_item_type !== 'trip') {
        if (meta?.isEditingRow?.(lineItem)) {
          return (
            <CurrencyInput
              data-testid="price-input"
              className="w-24"
              value={price}
              onChange={(e) => {
                meta.updateData?.(row.index, 'price', e.target.value);
              }}
              allowNegative
            />
          );
        }
      }
      return displayCell;
    },
  }),
  columnHelper.display({
    header: ' ',
    cell({
      row,
      table: {
        options: { meta },
      },
    }) {
      const lineItem = row.original;
      if (
        lineItem.line_item_type === 'trip' ||
        !meta?.isEditingRow?.(lineItem)
      ) {
        return null;
      }

      return (
        <Button
          className="flex gap-2"
          onClick={() => meta.deleteRow?.(row.index, row.original)}
        >
          <TrashIcon className="w-5" />
        </Button>
      );
    },
  }),
];

export function InvoiceLineItemList({
  lineItems,
  onUpdateLineItem,
  onDeleteLineItem,
  onDoneLoading,
}: InvoiceLineItemListProps) {
  const [fetchTrip] = useLazyTripsRetrieveQuery();
  const [isLoading, setLoading] = useState(true);
  const [hydratedListItems, setHydratedListItems] = useState<
    HydratedTripLineItem[]
  >([]);
  const editingLineItemGroup = useAppSelector(
    (state) => state.invoice.editingLineItemGroup
  );

  useEffect(() => {
    setLoading(true);

    Promise.all(
      lineItems.map(async (item) => {
        const tripResult = await fetchTrip(
          { id: item.trip ?? '' },
          true
        ).unwrap();
        return {
          ...item,
          trip: tripResult,
        };
      })
    )
      .then((result) => {
        setHydratedListItems(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [lineItems, fetchTrip, setLoading, setHydratedListItems]);

  useEffect(() => {
    if (!isLoading) {
      onDoneLoading?.(lineItems[0].trip ?? '');
    }
  }, [isLoading, lineItems, onDoneLoading]);

  if (isLoading) {
    return (
      <div className="flex flex-row justify-center">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <DataGrid
      tableId="invoice-line-item"
      columns={columns}
      data={hydratedListItems}
      includePagination={false}
      headerClassName="bg-slate-100"
      cellClassName="align-top"
      containerClassName="print:max-w-[100vw]"
      isEditingRow={(row) => row.trip.id === editingLineItemGroup}
      onUpdateData={(rowIndex, columnId, value) => {
        const update: InvoiceNestedLineItem = {
          ...lineItems[rowIndex],
        };

        if (columnId === 'price') {
          update['amount_cents'] = currency(value as string).multiply(
            100
          ).value;
        } else if (columnId === 'description') {
          update['description'] = value as string;
        }

        onUpdateLineItem(rowIndex, update);
      }}
      onDeleteRow={(rowIndex) => {
        onDeleteLineItem(rowIndex, lineItems[rowIndex]);
      }}
    />
  );
}
