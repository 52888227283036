import { createColumnHelper } from '@tanstack/react-table';

import store from 'src/app/store';
import { CredentialTypeColumnFilter } from 'src/common/datagridColumnFilters/CredentialTypeColumnFilter';
import { MembershipColumnFilter } from 'src/common/datagridColumnFilters/MembershipColumnFilter';
import { VehicleColumnFilter } from 'src/common/datagridColumnFilters/VehicleColumnFilter';
import { CredentialRead } from 'src/common/external/bambi-api/bambiApi';

import {
  setSelectedCredentialTypes,
  setSelectedMembers,
  setSelectedVehicles,
} from '../credential.slice';
import { EditCredentialButton } from './EditCredentialButton';

const columnHelper = createColumnHelper<CredentialRead>();

export const columns = [
  columnHelper.accessor('membership', {
    enableSorting: true,
    id: 'membership',
    header: 'Member',
    cell: ({ row }) => {
      return (
        `${row.original.membership.first_name} ${row.original.membership.last_name}` ||
        ''
      );
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return (
          <MembershipColumnFilter
            selectedMembers={store.getState().credential.selectedMembers}
            onChange={(values) => store.dispatch(setSelectedMembers(values))}
            open={open}
            onClose={onClose}
          />
        );
      },
      filterCount() {
        return store.getState().credential.selectedMembers.length;
      },
    },
  }),
  columnHelper.accessor('vehicle', {
    enableSorting: true,
    id: 'vehicle',
    header: 'Vehicle',
    cell: ({ row }) => {
      return row.original.vehicle.nickname || '';
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return (
          <VehicleColumnFilter
            selectedVehicles={store.getState().credential.selectedVehicles}
            onChange={(values) => store.dispatch(setSelectedVehicles(values))}
            open={open}
            onClose={onClose}
          />
        );
      },
      filterCount() {
        return store.getState().credential.selectedVehicles.length;
      },
    },
  }),
  columnHelper.accessor('credential_type.name', {
    enableSorting: true,
    id: 'credential_type',
    header: 'Name',
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return (
          <CredentialTypeColumnFilter
            selectedCredentialTypes={
              store.getState().credential.selectedCredentialTypes
            }
            onChange={(values) =>
              store.dispatch(setSelectedCredentialTypes(values))
            }
            open={open}
            onClose={onClose}
          />
        );
      },
      filterCount() {
        return store.getState().credential.selectedCredentialTypes.length;
      },
    },
  }),
  columnHelper.accessor('credential_type.description', {
    id: 'description',
    header: 'Description',
  }),
  columnHelper.accessor('expiration_date', {
    enableSorting: true,
    id: 'expiration_date',
    header: 'Expiration Date',
  }),

  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => <EditCredentialButton credential={props.row.original} />,
  }),
];
