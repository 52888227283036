import ProgressBarIndicator from './ProgressBarIndicator';

export function ProgressBar({ step, total }: { step: number; total: number }) {
  const progress = Math.round((step / total) * 100);

  return (
    <div className="flex w-full items-center justify-end gap-2">
      <div className="w-1/2">
        <ProgressBarIndicator progressPercentage={progress} />
      </div>
      <div className="whitespace-nowrap text-gray-500">
        Step {step} of {total}
      </div>
    </div>
  );
}
