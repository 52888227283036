import { useEffect, useState } from 'react';

import {
  ChatBubbleBottomCenterTextIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormError } from 'src/common/FormField/FormError';
import { Combobox } from 'src/common/primitives/ComboBox';
import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { SelectOption } from 'src/common/primitives/Select';
import { buildPassengerSelectOption } from 'src/features/add-trip/PassengerInfoFormSection/buildPassengerSelectOption';
import { usePassengerOptions } from 'src/features/add-trip/PassengerInfoFormSection/usePassengerOptions';

import { addATrip } from '../addATrip.slice';

interface ExistingPassengerSearchProps {
  selectedPassenger: PassengerRead | null | undefined;
  onPassengerSelected: (passenger: PassengerRead | undefined) => void;
  disabled?: boolean;
}

export function ExistingPassengerSearch({
  selectedPassenger,
  onPassengerSelected,
  disabled,
}: ExistingPassengerSearchProps) {
  const formContext = useFormContext();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<SelectOption | undefined>(undefined);

  const { debounceSearch, options, query, queryState, setQuery } =
    usePassengerOptions();

  useEffect(() => {
    if (selectedPassenger?.id) {
      formContext.clearErrors('passenger.id');
      setValue(buildPassengerSelectOption(selectedPassenger));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPassenger]);

  const onPassengerChange = (selectedPassengerOption: SelectOption) => {
    if (selectedPassengerOption.value === 'add-passenger' && query) {
      const querySplit = query.split(' ');
      const temp = {
        first_name: querySplit[0],
        last_name: querySplit[1],
      };

      dispatch(addATrip.actions.selectNoPassenger());
      dispatch(addATrip.actions.selectPassenger(temp));
      dispatch(addATrip.actions.setIsEditingPassenger(true));

      setValue({ label: '', value: '' });
      setQuery('');
      return;
    }

    const passengers = queryState.data?.results || [];
    const passenger = passengers.find(
      (p) => p.id === selectedPassengerOption.value
    );
    onPassengerSelected(passenger);
  };

  return (
    <FormContextField
      name="passenger.id"
      rules={{
        required: 'Passenger is required',
      }}
    >
      {({ field, fieldState }) => {
        return (
          <>
            <Combobox
              value={value}
              placeholder="Search for a passenger or add a new one here"
              options={options}
              onQueryChange={debounceSearch}
              onChange={(selected) => {
                onPassengerChange(selected);
                field.onChange(selected.value);
              }}
              error={fieldState.error?.message?.toString()}
              dataTestId="existing-passenger-search"
              disabled={disabled}
              renderOption={(option, selected, active) => {
                const passengers = queryState.data?.results || [];
                const passenger = passengers.find((p) => p.id === option.value);

                return (
                  <StyledOption
                    key={option.value}
                    option={option}
                    selected={selected}
                    active={active}
                    startAddornment={
                      <PassengerListItemAdornment passenger={passenger} />
                    }
                  />
                );
              }}
            />
            {fieldState.error?.message && (
              <FormError error={fieldState.error?.message}></FormError>
            )}
          </>
        );
      }}
    </FormContextField>
  );
}

export function PassengerListItemAdornment({
  passenger,
}: {
  passenger?: PassengerRead;
}) {
  if (!passenger) {
    return null;
  }

  return (
    <span className="flex flex-row gap-2">
      {passenger.has_tilled_account ? (
        <CreditCardIcon
          className="h-4 w-4"
          title="Payment method on file"
          data-testid="payment-method-icon"
        />
      ) : null}
      {passenger.allow_sms_message ? (
        <ChatBubbleBottomCenterTextIcon
          className="h-4 w-4"
          title="SMS enabled"
          data-testid="sms-icon"
        />
      ) : null}
    </span>
  );
}
