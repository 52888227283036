import { useEffect } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isUndefined } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/app/store';
import {
  useMessagesTripMessagesListQuery,
  useTripsCreatesListQuery,
  useTripsEventsListQuery,
  useTripsLocationsListQuery,
  useTripsSignaturesListQuery,
  useTripsUpdatesListQuery,
} from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryRouteParams } from '../routes';
import { tripSummarySlice } from './tripSummary.slice';

export function useTripSummaryRouteViewModel() {
  const dispatch = useAppDispatch();

  const { tripId } = useParams<TripSummaryRouteParams>();
  const { data, isLoading, isFetching, error } = useTripsEventsListQuery({
    id: tripId || '',
  });

  const createEventRequestState = useTripsCreatesListQuery({
    id: tripId || '',
  });

  const updateEventRequestState = useTripsUpdatesListQuery({
    id: tripId || '',
  });

  const tripLocationsRequestState = useTripsLocationsListQuery({
    id: tripId || '',
  });

  const tripMessages = useMessagesTripMessagesListQuery(
    {
      tripId,
    },
    {
      skip: !tripId,
    }
  );

  const tripSignatures = useTripsSignaturesListQuery(
    {
      id: tripId || '',
    },
    {
      skip: !tripId,
    }
  );

  useEffect(() => {
    if (data?.results.length) {
      dispatch(tripSummarySlice.actions.loadTripEvents(data.results));
    }
  }, [data?.results, dispatch]);

  useEffect(() => {
    if (createEventRequestState.data?.results.length) {
      dispatch(
        tripSummarySlice.actions.loadTripCreateEvents(
          createEventRequestState.data.results
        )
      );
    }
  }, [createEventRequestState.data?.results, dispatch]);

  useEffect(() => {
    if (updateEventRequestState.data?.results.length) {
      dispatch(
        tripSummarySlice.actions.loadTripUpdateEvents(
          updateEventRequestState.data.results
        )
      );
    }
  }, [updateEventRequestState.data?.results, dispatch]);

  useEffect(() => {
    if (tripLocationsRequestState.data?.results.length) {
      dispatch(
        tripSummarySlice.actions.loadTripLocations(
          tripLocationsRequestState.data.results
        )
      );
    }
  }, [tripLocationsRequestState.data?.results, dispatch]);

  useEffect(() => {
    dispatch(
      tripSummarySlice.actions.loadTripMessages(
        tripMessages.data?.results ?? []
      )
    );
  }, [tripMessages.data?.results, dispatch]);

  useEffect(() => {
    dispatch(
      tripSummarySlice.actions.loadTripSignatures(
        tripSignatures.data?.results ?? []
      )
    );
  }, [tripSignatures.data?.results, dispatch]);

  return {
    shouldShowLoadingState:
      isLoading ||
      isFetching ||
      createEventRequestState.isLoading ||
      createEventRequestState.isFetching ||
      updateEventRequestState.isLoading ||
      updateEventRequestState.isFetching ||
      tripLocationsRequestState.isLoading ||
      tripLocationsRequestState.isFetching ||
      tripMessages.isLoading ||
      tripMessages.isFetching ||
      tripSignatures.isLoading ||
      tripSignatures.isFetching,
    errors: [
      error,
      createEventRequestState.error,
      updateEventRequestState.error,
      tripLocationsRequestState.error,
      tripMessages.error,
      tripSignatures.error,
    ].filter((error) => !isUndefined(error)) as (
      | FetchBaseQueryError
      | SerializedError
    )[],
    tripId,
  };
}
