import { SignatureRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryEventPersonName } from '../TripSummaryEventPersonName';
import { TripSummaryOccuredAt } from '../TripSummaryOccuredAt';
import { TripSummaryTitle } from '../TripSummaryTitle';

export default function TripSummarySignatureItemHeader({
  tripSignature,
  isSelected,
}: {
  tripSignature: SignatureRead;
  isSelected: boolean;
}) {
  return (
    <div
      className={`flex w-full flex-col gap-1  p-2 pr-12 text-left ${
        isSelected ? 'bg-mint-light' : 'bg-gray-50'
      }`}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <TripSummaryTitle title="Signature" />

        <TripSummaryOccuredAt occuredAt={tripSignature.created_at} />
      </div>
      <div className="flex w-full flex-wrap items-center justify-between">
        <div>&nbsp;</div>
        <TripSummaryEventPersonName
          name={`${tripSignature.first_name} ${tripSignature.last_name}`}
        />
      </div>
    </div>
  );
}
