import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function SortableItem({
  id,
  isBeingDragged,
  children,
}: {
  id: string;
  isBeingDragged: boolean;
  children: React.ReactNode;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isBeingDragged ? 0 : 1,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={isBeingDragged ? 'cursor-grabbing' : 'cursor-grab'}
      title="Drag to reorder"
    >
      {children}
    </li>
  );
}
