import { CopyIcon } from '@radix-ui/react-icons';

import { useModal } from 'src/common/modals/useModal';
import { Button } from 'src/common/primitives/Button/Button';

import { PricingWizardModal } from '../AddPricingSchemeButton';

export function ClonePricingSchemeButton({
  pricingSchemeId,
}: {
  pricingSchemeId: string;
}) {
  const { open, setOpen, openModal } = useModal();
  return (
    <>
      <Button
        dataTestId="pricing-management-clone-button"
        onClick={openModal}
        variant="ghost"
      >
        <CopyIcon className="h-5 w-5 cursor-pointer hover:text-mint" />
      </Button>

      <PricingWizardModal
        open={open}
        setOpen={setOpen}
        pricingSchemeId={pricingSchemeId}
        mode="clone"
      />
    </>
  );
}
