import { useCallback } from 'react';

import { groupBy, map } from 'lodash-es';

import { useAppSelector } from 'src/app/store';
import { ACTIVE_TRIP_STATUSES } from 'src/common/ACTIVE_TRIP_STATUSES';
import {
  OrderedMultiloadTripLocationDetail,
  useTripsMultiloadDestroyMutation,
  useTripsMultiloadPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';

export function useBulkUnMultiLoadOnBulkUnassign() {
  const dispatchResponse = useAppSelector(
    (state) => state.dispatcher.dispatchResponse
  );
  const [deleteTripMultiLoad] = useTripsMultiloadDestroyMutation();
  const [updateTripMultiLoad] = useTripsMultiloadPartialUpdateMutation();

  const removeMultiLoads = useCallback(
    async (assignmentId: string) => {
      const multiLoadedTripsForAssignment =
        dispatchResponse?.assigned_trips.filter((trip) => {
          return trip.assignment_id === assignmentId && trip.multiload_id;
        }) || [];

      const multiLoadToTripsMap = groupBy(
        multiLoadedTripsForAssignment,
        'multiload_id'
      );

      return Promise.all(
        map(multiLoadToTripsMap, (tripsForMultiLoad, multiLoadId) => {
          const areAnyTripsLocked = tripsForMultiLoad.some(
            (trip) => trip.is_locked
          );
          const areAnyTripsInProgress = tripsForMultiLoad.some((trip) =>
            ACTIVE_TRIP_STATUSES.includes(trip.status)
          );

          if (!(areAnyTripsLocked || areAnyTripsInProgress)) {
            return deleteTripMultiLoad({ id: multiLoadId });
          }

          const areAllTripsLocked = tripsForMultiLoad.every(
            (trip) => trip.is_locked
          );
          const areAllTripsInProgress = tripsForMultiLoad.every((trip) =>
            ACTIVE_TRIP_STATUSES.includes(trip.status)
          );

          if (areAllTripsLocked || areAllTripsInProgress) {
            return Promise.resolve();
          }
          const tripLocationDetailsToRemoveFromMultiLoad: string[] = [];
          const currentTripMultiLoadDetails: OrderedMultiloadTripLocationDetail[] =
            [];
          tripsForMultiLoad.forEach((trip) => {
            if (
              !(trip.is_locked || ACTIVE_TRIP_STATUSES.includes(trip.status))
            ) {
              tripLocationDetailsToRemoveFromMultiLoad.push(
                trip.pickup_trip_location_detail_id as string,
                trip.dropoff_trip_location_detail_id as string
              );
            }
            currentTripMultiLoadDetails.push(
              {
                trip_location_detail_id:
                  trip.pickup_trip_location_detail_id as string,
                order: trip.pickup_order as number,
              },
              {
                trip_location_detail_id:
                  trip.dropoff_trip_location_detail_id as string,
                order: trip.dropoff_order as number,
              }
            );
          });

          const updatedTripLocationDetails = currentTripMultiLoadDetails.filter(
            (tripLocationDetail) => {
              return !tripLocationDetailsToRemoveFromMultiLoad.includes(
                tripLocationDetail.trip_location_detail_id
              );
            }
          );

          if (updatedTripLocationDetails.length < 4) {
            return deleteTripMultiLoad({ id: multiLoadId });
          } else {
            return updateTripMultiLoad({
              id: multiLoadId,
              patchedMultiload: {
                multiload_trip_location_details: updatedTripLocationDetails
                  .sort((a, b) => {
                    return a.order - b.order;
                  })
                  .map((orderedDetail, index) => {
                    return {
                      ...orderedDetail,
                      order: index + 1,
                    };
                  }),
              },
            });
          }
        })
      );
    },
    [deleteTripMultiLoad, dispatchResponse?.assigned_trips, updateTripMultiLoad]
  );

  return removeMultiLoads;
}
