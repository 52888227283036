import { useEffect, useState } from 'react';

import { useCredentialsTypesListQuery } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import { CredentialCategory } from '../types';

export function useCredentialTypeOptions(category: CredentialCategory) {
  const [query, setQuery] = useState<string | undefined>('');
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    data: credentialTypes,
    isLoading,
    error,
  } = useCredentialsTypesListQuery({ category });

  useEffect(() => {
    const queryResults = credentialTypes?.results || [];
    const addCredentialTypeOption = {
      label: `Add "${query}"`,
      value: 'add-credential-type',
    };

    const credentialTypeOptions = queryResults.map((credentialType) => ({
      label: credentialType.name,
      value: credentialType.id,
    }));

    if (!query) {
      setOptions(credentialTypeOptions);
      return;
    }

    const filteredOptions = credentialTypeOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );

    setOptions([...filteredOptions, addCredentialTypeOption]);
  }, [query, credentialTypes?.results]);

  return {
    error,
    isLoading,
    options,
    credentialTypes,
    query,
    setQuery,
  };
}
