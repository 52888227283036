import { TrashIcon } from '@radix-ui/react-icons';
import { DateTime } from 'luxon';

import { AttendantRead } from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Badge } from 'src/common/primitives/Badge';
import { Button } from 'src/common/primitives/Button';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect';
import { formatFullName } from 'src/common/util/formatFullName';

import { AssignmentEndTimeInput } from './AssignmentEndTimeInput';
import { AssignmentStartTimeInput } from './AssignmentStartTimeInput';
import { CloneAssignmentAction } from './CloneAssignmentAction';
import { useAssignmentEditorViewModel } from './useAssignmentEditorViewModel/useAssignmentEditorViewModel';

type AssignmentEditorProps = {
  day: DateTime;
  index?: number;
  isAvailable?: boolean;
  isEditing?: boolean;
};

export function AssignmentEditor({
  day,
  index,
  isAvailable,
  isEditing,
}: AssignmentEditorProps) {
  const {
    isEditingExistingAssignment,
    handleDeleteAssignment,
    attendantOptions,
    attendantsData,
    fieldNameRoot,
    driverOptions,
    drivers,
    driversLoading,
    driverLocked,
    attendantsLoading,
  } = useAssignmentEditorViewModel({
    day,
  });

  if (driversLoading || attendantsLoading) {
    return <div>Loading...</div>;
  }

  if (!isAvailable) {
    return <span className="text-xs">Unavailable</span>;
  }

  if (!isEditingExistingAssignment && !isEditing) {
    return <span className="text-xs">No Schedule</span>;
  }
  return (
    <div className="flex flex-col gap-y-2">
      {!isEditingExistingAssignment && typeof index === 'number' && (
        <div
          className={`flex items-center justify-between ${index > 0 && 'pt-2'}`}
        >
          <Badge
            color="primary"
            label={`${day.weekdayLong} Assignment ${index + 1}`}
          ></Badge>
          <CloneAssignmentAction assignmentFormValuePath={fieldNameRoot} />
          <Button
            variant="ghost"
            onClick={() => handleDeleteAssignment(index)}
            dataTestId="assignment-delete-button"
          >
            <TrashIcon className="h-5 w-5 cursor-pointer text-red-500" />
          </Button>
        </div>
      )}
      <div className="flex items-center justify-between gap-x-4 whitespace-nowrap">
        <AssignmentStartTimeInput />
        <div className="text-xl"> - </div>
        <AssignmentEndTimeInput />
      </div>
      <div>
        <FormContextField
          name={`${fieldNameRoot}.driver`}
          rules={{
            required: 'Driver is required',
          }}
        >
          {({ field, fieldState }) => {
            return (
              <>
                <FormField
                  type="select"
                  placeholder="Select driver"
                  inputProps={{
                    id: `${fieldNameRoot}.driver`,
                    ...field,
                  }}
                  options={driverOptions()}
                  onChange={(selected) => {
                    const driver = drivers.find(
                      (x) => x.id === selected?.value
                    );
                    field.onChange(driver);
                  }}
                  value={
                    field.value?.id
                      ? {
                          value: field.value.id,
                          label: formatFullName(field.value),
                        }
                      : undefined
                  }
                  helperText={driverLocked ? lockedDriverHelperText : undefined}
                  helperTextPosition="absolute right-full w-[283px]"
                  disabled={driverLocked}
                  error={fieldState.error?.message?.toString()}
                />
              </>
            );
          }}
        </FormContextField>
      </div>
      <div>
        <FormContextField name={`${fieldNameRoot}.attendants`}>
          {({ field, fieldState }) => {
            return (
              <MultiSelect
                placeholder="Select attendant"
                selected={field.value?.map((x: AttendantRead) => {
                  return { value: x.id, label: formatFullName(x) };
                })}
                options={attendantOptions()}
                onChange={(selected) => {
                  const attendants = selected.map((x) => {
                    return attendantsData?.results.find(
                      (y) => y.id === x.value
                    );
                  });
                  field.onChange(attendants);
                }}
              />
            );
          }}
        </FormContextField>
      </div>
    </div>
  );
}

const lockedDriverHelperText =
  'This assignment has already started so the driver cannot be changed. ' +
  'To change the driver, create a new assignment and transfer trips as needed.';
