import { useMemo } from 'react';

import { useFeature } from 'src/app/FeatureToggle/useFeature';
import { NavItem } from 'src/common/NavBar/TopLevelNavItemContext';

export function useFleetNavItems(navItems: NavItem[]) {
  const { isEnabled, isLoading } = useFeature('vehicle credentials');

  const tabs = useMemo(() => {
    const credentialsTabExists = navItems.some(
      (tab) => tab.name === 'Credentials'
    );

    if (!isLoading && isEnabled && !credentialsTabExists) {
      return [
        ...navItems,
        {
          name: 'Credentials',
          href: '/fleet/credentials',
        },
      ];
    }

    return navItems;
  }, [navItems, isEnabled, isLoading]);

  return tabs;
}
