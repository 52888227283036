import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import * as Accordion from 'src/common/Accordion';
import {
  SignatureRead,
  TripCreateRead,
  TripEventRead,
  TripMessageRead,
  TripUpdateRead,
} from 'src/common/external/bambi-api/bambiApi';

import { resolveAccordionValue } from './resolveAccordionValue';
import { selectCurrentTripSummaryId } from './selectors/selectCurrentTripSummaryId';
import { selectTripCreateEvents } from './selectors/selectTripCreateEvents';
import { selectTripEvents } from './selectors/selectTripEvents';
import { selectTripUpdateEvents } from './selectors/selectTripUpdateEvents';
import { tripSummarySlice } from './tripSummary.slice';
import { TripSummaryCreateItem } from './TripSummaryCreateItem';
import { TripSummaryItem } from './TripSummaryItem';
import { TripSummaryMessageItem } from './TripSummaryMessageItem/TripSummaryMessageItem';
import TripSummarySignatureItem from './TripSummarySignatureItem/TripSummarySignatureItem';
import { TripSummaryUpdateItem } from './TripSummaryUpdateItem';
import { tripUpdateEventHasUnskippedChangedValues } from './tripUpdateEventHasUnskippedChangedValues';

function isTripEvent(
  tripEvent:
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
): tripEvent is TripEventRead {
  return 'occurred_at' in tripEvent;
}

function isTripUpdate(
  tripEvent:
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
): tripEvent is TripUpdateRead {
  return 'values_changed' in tripEvent;
}

function isTripCreate(
  tripEvent:
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
): tripEvent is TripCreateRead {
  return 'parsed_trip' in tripEvent;
}

function isTripMessage(
  tripMessage:
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
): tripMessage is TripMessageRead {
  return 'message_type' in tripMessage;
}

function isTripSignature(
  tripSignature:
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
): tripSignature is SignatureRead {
  return 'signed_items' in tripSignature;
}

export function TripSummaryItemList() {
  const dispatch = useAppDispatch();
  const tripEvents = useAppSelector(selectTripEvents);
  const tripCreateEvents = useAppSelector(selectTripCreateEvents);
  const tripUpdateEvents = useAppSelector(selectTripUpdateEvents);
  const selectedTripEventId = useAppSelector(selectCurrentTripSummaryId);
  const tripMessages = useAppSelector(
    (state) => state.tripSummary.tripMessages
  );
  const tripSignatures = useAppSelector(
    (state) => state.tripSummary.tripSignatures
  );

  const accordionValue = resolveAccordionValue(
    tripCreateEvents,
    tripEvents,
    tripUpdateEvents,
    tripMessages,
    tripSignatures,
    selectedTripEventId
  );

  const filteredUpdateEvents = tripUpdateEvents.filter(
    tripUpdateEventHasUnskippedChangedValues
  );

  const mergedTripEvents: (
    | TripCreateRead
    | TripUpdateRead
    | TripEventRead
    | TripMessageRead
    | SignatureRead
  )[] = sortBy(
    [
      ...tripCreateEvents,
      ...filteredUpdateEvents,
      ...tripEvents,
      ...tripMessages,
      ...tripSignatures,
    ],
    (x) => {
      if (isTripEvent(x)) {
        return DateTime.fromISO(x.occurred_at).toUTC().toUnixInteger();
      }

      if (isTripUpdate(x)) {
        return DateTime.fromISO(x.created_at).toUTC().toUnixInteger();
      }

      if (isTripCreate(x) && typeof x.parsed_trip?.created_at === 'string') {
        return DateTime.fromISO(x.parsed_trip.created_at)
          .toUTC()
          .toUnixInteger();
      }
      if (isTripMessage(x)) {
        return DateTime.fromISO(x.message.created_at).toUTC().toUnixInteger();
      }

      if (isTripSignature(x)) {
        return DateTime.fromISO(x.created_at).toUTC().toUnixInteger();
      }
    }
  ).reverse();

  return (
    <Accordion.Root type="single" collapsible itemID="" value={accordionValue}>
      <div className="relative isolate flex flex-col gap-y-2 p-3">
        {mergedTripEvents.map((tripEvent) => {
          if (isTripEvent(tripEvent)) {
            return (
              <TripSummaryItem
                key={tripEvent.id}
                tripEvent={tripEvent}
                isSelected={tripEvent.id === selectedTripEventId}
                onClick={() => {
                  if (selectedTripEventId === tripEvent.id) {
                    dispatch(tripSummarySlice.actions.deselectTripSummary());
                    return;
                  }
                  dispatch(
                    tripSummarySlice.actions.selectTripSummary(tripEvent.id)
                  );
                }}
              />
            );
          }

          if (isTripCreate(tripEvent)) {
            return (
              <TripSummaryCreateItem
                key={tripEvent.id}
                tripEvent={tripEvent}
                onClick={() => {
                  if (selectedTripEventId === String(tripEvent.id)) {
                    dispatch(tripSummarySlice.actions.deselectTripSummary());
                    return;
                  }
                  dispatch(
                    tripSummarySlice.actions.selectTripSummary(
                      String(tripEvent.id)
                    )
                  );
                }}
                isSelected={String(tripEvent.id) === selectedTripEventId}
              />
            );
          }
          if (isTripUpdate(tripEvent)) {
            return (
              <TripSummaryUpdateItem
                key={tripEvent.id}
                tripEvent={tripEvent}
                onClick={() => {
                  if (selectedTripEventId === String(tripEvent.id)) {
                    dispatch(tripSummarySlice.actions.deselectTripSummary());
                    return;
                  }
                  dispatch(
                    tripSummarySlice.actions.selectTripSummary(
                      String(tripEvent.id)
                    )
                  );
                }}
                isSelected={String(tripEvent.id) === selectedTripEventId}
              />
            );
          }

          if (isTripMessage(tripEvent)) {
            const messageId = `message-${tripEvent.id}`;
            return (
              <TripSummaryMessageItem
                key={messageId}
                tripMessage={tripEvent}
                isSelected={messageId === selectedTripEventId}
                onClick={() => {
                  if (selectedTripEventId === messageId) {
                    dispatch(tripSummarySlice.actions.deselectTripSummary());
                    return;
                  }
                  dispatch(
                    tripSummarySlice.actions.selectTripSummary(messageId)
                  );
                }}
              />
            );
          }

          if (isTripSignature(tripEvent)) {
            const signatureId = `signature-${tripEvent.id}`;
            return (
              <TripSummarySignatureItem
                key={signatureId}
                tripSignature={tripEvent}
                isSelected={signatureId === selectedTripEventId}
                onClick={() => {
                  if (selectedTripEventId === signatureId) {
                    dispatch(tripSummarySlice.actions.deselectTripSummary());
                    return;
                  }
                  dispatch(
                    tripSummarySlice.actions.selectTripSummary(signatureId)
                  );
                }}
              />
            );
          }
          return null;
        })}
      </div>
    </Accordion.Root>
  );
}
