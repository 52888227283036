import { Route, Routes } from 'react-router';

import { CredentialRouteActions } from 'src/common/credentials/CredentialRouteActions';
import { VehicleInspectionsRouteActions } from 'src/features/fleet/inspection/VehicleInspectionsRouteActions';
import { VehicleManagementRouteActions } from 'src/features/fleet/management/VehicleManagementRouteActions';

export function FleetRouteActions() {
  return (
    <Routes>
      <Route element={<VehicleManagementRouteActions />} index />
      <Route path="inspections" element={<VehicleInspectionsRouteActions />} />
      <Route path="credentials" element={<CredentialRouteActions />} />
    </Routes>
  );
}
