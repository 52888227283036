import { useContext } from 'react';

import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { validateNoCC } from 'src/common/FormField/validators/creditCardValidator';

import { DynamicFieldNameRootContext } from './DynamicFieldNameRootContext';
import { useApplyPassengerDispatchNotes } from './useApplyPassengerDispatchNotes';

export function DispatcherNotes() {
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  useApplyPassengerDispatchNotes();
  return (
    <div className="flex flex-grow flex-col">
      <FormContextField
        name={`${fieldNameRoot}.dispatcher_notes`}
        rules={{
          validate: validateNoCC,
        }}
      >
        {({ field, fieldState }) => {
          return (
            <FormField
              label="Dispatcher Notes (Not visible to drivers)"
              type="textarea"
              error={fieldState.error?.message?.toString()}
              inputProps={{
                id: `${fieldNameRoot}.dispatcher_notes`,
                ...field,
                rows: '1',
                placeholder:
                  'i.e. which driver is preferred for this passenger?',
              }}
            />
          );
        }}
      </FormContextField>
    </div>
  );
}
