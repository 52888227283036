import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { useCredentialsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import CredentialForm from '../CredentialForm/CredentialForm';
import CredentialTypeForm from '../CredentialTypeForm/CredentialTypeForm';
import { CredentialCategory } from '../types';
import { columns } from './columns';
import CredentialsEmptyState from './CredentialsEmptyState';

interface CredentialsDataGridProps {
  category: CredentialCategory;
}

export function CredentialsDataGrid({ category }: CredentialsDataGridProps) {
  const ordering = useSearchParams()[0].get('ordering');

  const {
    credentialSearchTerm,
    selectedVehicles,
    selectedCredentialTypes,
    selectedMembers,
  } = useAppSelector((state) => state.credential);

  const { data, isLoading, isFetching } = useCredentialsListQuery({
    credentialTypeCategory: category,
    ordering: ordering || '',
    search: credentialSearchTerm,
    vehicles: selectedVehicles.join(','),
    credentialTypes: selectedCredentialTypes.join(','),
    members: selectedMembers.join(','),
  });

  const categoryColumns = columns.filter((column) => {
    if (category === 'Team') {
      return column.id !== 'vehicle';
    } else if (category === 'Vehicle') {
      return column.id !== 'membership';
    }
    return true;
  });

  return (
    <>
      {isLoading || isFetching ? (
        <div className="flex justify-center pt-24">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          <DataGrid
            tableId={`credentials-${category}`}
            columns={categoryColumns}
            data={data?.results || []}
            totalCount={data?.count || 0}
            emptyState={<CredentialsEmptyState category={category} />}
            getRowId={(row) => row.id}
          />
        </div>
      )}
      <CredentialForm category={category} />
      <CredentialTypeForm category={category} />
    </>
  );
}
