import {
  useLazyTripsMultiloadRetrieveQuery,
  useLazyTripsRetrieveQuery,
  useTripsMultiloadDestroyMutation,
  useTripsMultiloadPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';

export function useRemoveTripFromMultiLoad() {
  const [getTrip] = useLazyTripsRetrieveQuery();
  const [getTripMultiLoad] = useLazyTripsMultiloadRetrieveQuery();
  const [updateTripMultiLoad] = useTripsMultiloadPartialUpdateMutation();
  const [deleteTripMultiLoad] = useTripsMultiloadDestroyMutation();

  return async function removeTripFromMultiLoad(
    tripId: string,
    multiLoadId: string
  ) {
    const [{ data: tripData }, { data: tripMultiLoad }] = await Promise.all([
      getTrip({ id: tripId }),
      getTripMultiLoad({ id: multiLoadId }),
    ]);
    if (!tripMultiLoad) {
      throw new Error(`Error getting trip multiload with id: ${multiLoadId}`);
    }
    if (!tripData) {
      throw new Error(`Error getting trip with id: ${tripId}`);
    }
    const updatedTripLocationDetails =
      tripMultiLoad.multiload_trip_location_details.filter(
        (tripLocationDetail) =>
          ![tripData.pickup.id, tripData.dropoff.id].includes(
            tripLocationDetail.trip_location_detail_id
          )
      );
    // If we don't have 2 trips left, we should delete the multiload
    if (updatedTripLocationDetails.length < 4) {
      return deleteTripMultiLoad({ id: multiLoadId });
    } else {
      return updateTripMultiLoad({
        id: multiLoadId,
        patchedMultiload: {
          multiload_trip_location_details: updatedTripLocationDetails
            .sort((orderedDetail) => {
              return orderedDetail.order;
            })
            .map((orderedDetail, index) => {
              return {
                ...orderedDetail,
                order: index + 1,
              };
            }),
        },
      });
    }
  };
}
