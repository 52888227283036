import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { truncate, upperFirst } from 'lodash-es';

import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber';

import { PassengerForm } from '../PassengerForm/PassengerForm';
import { PassengerProfileCell } from './PassengerProfileCell';

const columnHelper = createColumnHelper<PassengerRead>();

function RowActions({ passenger }: { passenger: PassengerRead }) {
  return (
    <div className="w-[24px]">
      <PassengerForm passenger={passenger} />
    </div>
  );
}

export const rideOrderingPortalColumns = [
  columnHelper.accessor('preferred_name', {
    enableSorting: true,
    id: 'full_name',
    header: 'Name',
    cell: (props) => <PassengerProfileCell passenger={props.row.original} />,
  }),
  columnHelper.accessor('phone_number', {
    enableSorting: true,
    id: 'phone_number',
    header: 'Phone Number',
    cell: (props) => formatPhoneNumber(props.row.original.phone_number || ''),
  }),
  columnHelper.accessor('email', {
    enableSorting: true,
    id: 'email',
    header: 'Email',
  }),
  columnHelper.accessor('dob', {
    enableSorting: true,
    id: 'dob',
    header: 'Date of Birth',
  }),
  columnHelper.accessor('gender', {
    header: 'Sex',
    cell: (props) => upperFirst(props.row.original.gender),
  }),
  columnHelper.accessor('weight', {
    header: 'Weight',
  }),
  columnHelper.accessor('needs_bariatric_transport', {
    header: 'Needs Bariatric',
    cell: (props) =>
      props.row.original.needs_bariatric_transport ? (
        <CheckIcon className="h-7 w-7 text-green-600" />
      ) : (
        <XMarkIcon className="h-7 w-7 text-red-600" />
      ),
  }),
];

export const columns = [
  ...rideOrderingPortalColumns,
  columnHelper.accessor('allow_sms_message', {
    header: 'Allow SMS',
    cell: (props) => {
      if (props.getValue()) {
        return (
          <CheckIcon
            className="h-7 w-7 text-green-600"
            aria-label={`SMS enabled for ${props.row.original.full_name}`}
          />
        );
      }
      return null;
    },
  }),
  columnHelper.accessor('notes', {
    header: 'Notes',
    cell: (props) =>
      props.row.original.notes
        ? truncate(props.row.original.notes, { length: 50, omission: '...' })
        : '',
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => <RowActions passenger={props.row.original} />,
  }),
];
