import { CredentialRead } from 'src/common/external/bambi-api/bambiApi';

export const assignDefaultFormValues = (credential?: CredentialRead) => {
  return {
    id: credential?.id || '',
    credential_type_id: credential?.credential_type_id,
    membership_id: credential?.membership?.id,
    vehicle_id: credential?.vehicle?.id,
    expiration_date: credential?.expiration_date || '',
    notes: credential?.notes || undefined,
  };
};
