import { NavItem } from 'src/common/NavBar/TopLevelNavItemContext';
import { TabNavigation } from 'src/common/TabNavigation';

import { useFleetNavItems } from './useFleetNavItems';

const fleetTabs: NavItem[] = [
  { name: 'Vehicles', href: '/fleet' },
  { name: 'Inspections', href: '/fleet/inspections' },
];

export function FleetNav() {
  const fleetTabNavItems = useFleetNavItems(fleetTabs);

  return <TabNavigation tabs={fleetTabNavItems} />;
}
