import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import {
  DispatchTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';

interface Props {
  trip: DispatchTripRead | TripRead;
}

export function CancellationMenu({ trip }: Props) {
  const menuActions = useTripMenuActions();
  const allowUncancel =
    !trip.intermediary_id ||
    (trip.intermediary !== 'momentm' && trip.intermediary !== 'kinetik');

  return (
    <>
      {trip.status === 'canceled' ? (
        allowUncancel ? (
          <UncancelMenuItem tripId={trip.id} />
        ) : (
          <DisabledUncancelMenuItem />
        )
      ) : (
        <DropdownMenuItem onClick={() => menuActions.cancel(trip.id)}>
          Cancel
        </DropdownMenuItem>
      )}
    </>
  );
}

function UncancelMenuItem({ tripId }: { tripId: string }) {
  const menuActions = useTripMenuActions();

  return (
    <DropdownMenuItem onClick={() => menuActions.uncancel(tripId)}>
      Uncancel
    </DropdownMenuItem>
  );
}

function DisabledUncancelMenuItem() {
  return (
    <DropdownMenuItem>
      <SimpleTooltip
        trigger={<span className="disabled text-slate-400">Uncancel</span>}
        content="This trip is not able to be uncancelled due to Broker restrictions"
      />
    </DropdownMenuItem>
  );
}
