import { IToastConfig } from 'src/common/primitives/Toast';

export function generateCreateSuccessToast(id: number): IToastConfig {
  return {
    id,
    title: `Success`,
    description: 'The credential type has been created.',
    type: 'success',
  };
}
