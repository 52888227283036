import { useMemo } from 'react';

import { useFeature } from 'src/app/FeatureToggle';
import { NavItem } from 'src/common/NavBar/TopLevelNavItemContext';

export function useNonRideOrderingPortalTabs(initialTabs: NavItem[]) {
  const {
    isEnabled: isMemberCredentialsEnabled,
    isLoading: isMemberCredentialsLoading,
  } = useFeature('member credentials');

  const tabs = useMemo(() => {
    const credentialsTabExists = initialTabs.some(
      (tab) => tab.name === 'Credentials'
    );

    if (
      !isMemberCredentialsLoading &&
      isMemberCredentialsEnabled &&
      !credentialsTabExists
    ) {
      return [
        ...initialTabs,
        {
          name: 'Credentials',
          href: '/team/member-credentials',
        },
      ];
    }

    return initialTabs;
  }, [initialTabs, isMemberCredentialsEnabled, isMemberCredentialsLoading]);

  return tabs;
}
