import { createColumnHelper } from '@tanstack/react-table';

import store from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';
import { hasRangeFilter } from 'src/common/primitives/NumberRangeFilterPopover/NumberRangeFilterPopover';
import { PayersColumnFilter } from 'src/features/billing/invoices/management/filters/PayersColumnFilter';

import { ChargeStatusCell } from './cells/ChargeStatusCell';
import { ManageCell } from './cells/ManageCell';
import { PaymentStatusCell } from './cells/PaymentStatusCell';
import { DateIssuedColumnFilter } from './filters/DateIssuedColumnFilter';
import { InvoiceTotalColumnFilter } from './filters/InvoiceTotalColumnFilter';
import { InvoiceTotalDueColumnFilter } from './filters/InvoiceTotalDueColumnFilter';
import { PaymentStatusColumnFilter } from './filters/PaymentStatusColumnFilter';

const columnHelper = createColumnHelper<InvoiceRead>();

export const columns = [
  columnHelper.accessor('number', {
    header: 'Number',
    enableSorting: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('date_issued', {
    header: 'Date Issued',
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <DateIssuedColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const state = store.getState().invoice;
        const hasFilter = state.filterDateStart || state.filterDateEnd;

        return hasFilter ? 1 : 0;
      },
    },
  }),

  columnHelper.accessor('overall_payment_status', {
    header: 'Payment Status',
    cell({ getValue, row }) {
      const status = getValue();
      const anyPaymentsProcessing = row.original.payments?.find(
        (p) => p.charge_status === 'pending'
      );

      return (
        <PaymentStatusCell
          status={status ?? 'unknown'}
          anyPaymentsProcessing={!!anyPaymentsProcessing}
        />
      );
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <PaymentStatusColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        return store.getState().invoice.filterPaymentStatus?.length ?? 0;
      },
    },
  }),
  columnHelper.display({
    id: 'lastChargeStatus',
    header: 'Last Charge Status',
    cell({ row }) {
      return <ChargeStatusCell payments={row.original.payments ?? []} />;
    },
  }),
  columnHelper.accessor('total_amount_cents', {
    header: 'Invoice Total',
    cell({ getValue }) {
      return defaultCurrencyFormat(getValue());
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <InvoiceTotalColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const state = store.getState().invoice;
        const hasFilter = hasRangeFilter(state.filterTotalAmount);

        return hasFilter ? 1 : 0;
      },
    },
  }),
  columnHelper.accessor('total_amount_due_cents', {
    header: 'Invoice Total Due',
    cell({ getValue }) {
      return defaultCurrencyFormat(getValue());
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <InvoiceTotalDueColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const state = store.getState().invoice;
        const hasFilter = hasRangeFilter(state.filterTotalAmountDue);

        return hasFilter ? 1 : 0;
      },
    },
  }),
  columnHelper.accessor('notes', {
    header: 'Notes',
    cell({ getValue }) {
      return (
        <div className="truncate" style={{ maxWidth: '500px' }}>
          {getValue()}
        </div>
      );
    },
  }),
  columnHelper.accessor('line_items', {
    header: 'Payer',
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <PayersColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        return store.getState().invoice.filterTripPayers?.length ?? 0;
      },
    },
    cell({ getValue }) {
      return (
        <div className="truncate">{getPayersFromLineItems(getValue())}</div>
      );
    },
  }),
  columnHelper.display({
    id: 'total_trips',
    header: 'Trips Invoiced',
    cell(props) {
      const invoicedTrips = props.row.original.line_items.filter(
        (item) => item.line_item_type === 'trip'
      );
      return <div>{invoicedTrips.length ? invoicedTrips.length : ''}</div>;
    },
  }),
  columnHelper.display({
    id: 'manage',
    enableHiding: false,
    header() {
      return <div className="flex flex-row">Manage</div>;
    },
    cell(props) {
      return <ManageCell invoice={props.row.original} />;
    },
    meta: {
      headerWidth: 100,
    },
  }),
];

const getPayersFromLineItems = (lineItems: InvoiceRead['line_items']) => {
  return Array.from(
    new Set(
      lineItems
        .filter((lineItem) => lineItem.trip_payer?.display_name)
        .map((lineItem) => lineItem.trip_payer!.display_name)
    )
  )
    .sort()
    .join(', ');
};
