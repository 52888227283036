import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

import { serviceDetailsNameRoot } from './ServiceDetailsFormBody';

export default function HasInfectiousDiseaseField() {
  const fieldName = `${serviceDetailsNameRoot}.has_infectious_disease`;
  return (
    <FormContextField name={fieldName}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Infectious Disease?"
            error={fieldState.error?.message?.toString()}
            type="switch"
            inputProps={{
              id: fieldName,
              ...field,
            }}
          />
        );
      }}
    </FormContextField>
  );
}
