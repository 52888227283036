import * as Accordion from 'src/common/Accordion';
import { SignatureRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

import TripSummarySignatureItemHeader from './TripSummarySignatureItemHeader';
import { useSignatureDetails } from './useSignatureDetails';

export default function TripSummarySignatureItem({
  tripSignature,
  isSelected,
  onClick,
}: {
  tripSignature: SignatureRead;
  isSelected: boolean;
  onClick: () => void;
}) {
  const { signatureLocationType, badgeColor } =
    useSignatureDetails(tripSignature);

  return (
    <Accordion.Item value={`signature-${tripSignature.id}`} onClick={onClick}>
      <div className="relative rounded-lg border border-solid border-[#eaecf0] bg-gray-50 hover:cursor-pointer">
        <style>{`
            [data-radix-collection-item][data-state="open"] {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
        `}</style>
        <Accordion.Trigger>
          <TripSummarySignatureItemHeader
            tripSignature={tripSignature}
            isSelected={isSelected}
          />
        </Accordion.Trigger>
        <Accordion.Content>
          <div className="bg-white p-2 text-sm">
            <div className="flex justify-between">
              <Badge label={signatureLocationType} color="gray" />
              <Badge
                label={tripSignature.signature_type ?? ''}
                color={badgeColor}
              />
            </div>
            <img src={tripSignature.image} alt="Signature" />
          </div>
        </Accordion.Content>
      </div>
    </Accordion.Item>
  );
}
