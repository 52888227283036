import { cloneDeep } from 'lodash-es';

import { TripSubscriptionRead } from 'src/common/external/bambi-api/bambiApi';
import { safelyMerge } from 'src/common/util/safelyMerge';

import { defaultFormValues } from './defaultFormValues';
import generateFormRecurrencesFromDbRecurrences from './generateFormRecurrencesFromDbRecurrences';

export function assignDefaultFormValues(
  tripSubscription?: TripSubscriptionRead
) {
  if (!tripSubscription) {
    return defaultFormValues;
  }

  const tripSubCopy = cloneDeep(tripSubscription);
  const defaultFormValuesCopy = cloneDeep(defaultFormValues);

  return {
    id: tripSubCopy.id || defaultFormValuesCopy.id,
    dispatcher_notes:
      tripSubCopy.dispatcher_notes || defaultFormValuesCopy.dispatcher_notes,
    start_date: tripSubCopy.start_date || defaultFormValuesCopy.start_date,
    expires_at: tripSubCopy.expires_at || defaultFormValuesCopy.expires_at,
    pickup: {
      ...safelyMerge(defaultFormValuesCopy.pickup, tripSubCopy.pickup || {}),
      address: {
        label: tripSubCopy.pickup.address || '',
        value: tripSubCopy.pickup.place_id || '',
      },
      stairs:
        tripSubCopy.pickup.num_stairs || defaultFormValuesCopy.pickup.stairs,
      stair_equipment:
        tripSubCopy.pickup.stair_equipment ||
        defaultFormValuesCopy.pickup.stair_equipment,
      load_time_seconds: tripSubCopy.load_time_seconds || undefined,
    },
    dropoff: {
      ...safelyMerge(defaultFormValuesCopy.dropoff, tripSubCopy.dropoff || {}),
      address: {
        label: tripSubCopy.dropoff.address || '',
        value: tripSubCopy.dropoff.place_id || '',
      },
      stairs:
        tripSubCopy.dropoff.num_stairs || defaultFormValuesCopy.dropoff.stairs,
      stair_equipment:
        tripSubCopy.dropoff.stair_equipment ||
        defaultFormValuesCopy.dropoff.stair_equipment,
      unload_time_seconds: tripSubCopy.unload_time_seconds || undefined,
    },
    must_provide_wheelchair:
      tripSubCopy.must_provide_wheelchair ||
      defaultFormValuesCopy.must_provide_wheelchair,
    passenger: {
      ...safelyMerge(
        defaultFormValuesCopy.passenger,
        tripSubCopy.passenger || {}
      ),
      weight: tripSubCopy.passenger.weight || 0,
    },
    pricing: {
      payer: tripSubCopy.payer.id || defaultFormValuesCopy.pricing.payer,
      payment_method:
        tripSubCopy.payment_method ||
        defaultFormValuesCopy.pricing.payment_method,
      payer_passenger_external_id:
        tripSubCopy.payer_passenger?.external_id ||
        defaultFormValuesCopy.pricing.payer_passenger_external_id,
    },
    service_details: {
      space_type:
        tripSubCopy.space_type ||
        defaultFormValuesCopy.service_details.space_type,
      service_type:
        tripSubCopy.service_type ||
        defaultFormValuesCopy.service_details.service_type,
      is_oxygen_required: tripSubCopy.is_oxygen_required,
      oxygen_liters_per_min: tripSubCopy.oxygen_liters_per_min || '',
      num_attendants_needed: tripSubCopy.num_attendants_needed || 0,
      num_accompanying_passengers: tripSubCopy.num_accompanying_passengers || 0,
      has_infectious_disease: tripSubCopy.has_infectious_disease || false,
    },
    booked_by_name:
      tripSubCopy.booked_by_name || defaultFormValuesCopy.booked_by_name,
    booked_by_phone_number:
      tripSubCopy.booked_by_phone_number ||
      defaultFormValuesCopy.booked_by_phone_number,
    booked_by_phone_extension:
      tripSubCopy.booked_by_phone_extension ||
      defaultFormValuesCopy.booked_by_phone_extension,
    recurrences: tripSubCopy.recurrences
      ? generateFormRecurrencesFromDbRecurrences(tripSubCopy.recurrences)
      : defaultFormValuesCopy.recurrences,
  };
}
