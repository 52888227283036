import { CurrencyFormContextField } from 'src/common/FormField/CurrencyFormContextField';
import { SimpleContextFormField } from 'src/common/FormField/SimpleContextFormField';
import { useFormValues } from 'src/common/useFormValues';

import { SchemeConfigFormValues } from './PricingWizardSchemeConfigurationStep';

export function AfterHoursPricingRow() {
  const values = useFormValues<SchemeConfigFormValues>();
  // TODO: Add validation to business hours in the cases that fee is greater than 0
  // and both business hours are not set
  const businessHoursStart = values.business_hours_start_time;
  const businessHoursEnd = values.business_hours_end_time;
  return (
    <>
      <div>
        <CurrencyFormContextField
          fieldPath="after_hours_fee_cents"
          label="After Hours Fee"
          inline
          helperText="Enter the fee for trips that occur outside of regular business hours."
          rules={{
            validate: (
              value: SchemeConfigFormValues['after_hours_fee_cents']
            ) => {
              if (
                (businessHoursStart || businessHoursEnd) &&
                typeof value !== 'number'
              ) {
                return 'After hours fee is required when business hours are set.';
              }
              return true;
            },
          }}
        />
      </div>
      <div>
        <SimpleContextFormField
          fieldPath="business_hours_start_time"
          label="Business Hours Start"
          inline
          type="time"
          helperText="Specify the time before which trips are considered to be after hours."
        />
      </div>
      <div>
        <SimpleContextFormField
          fieldPath="business_hours_end_time"
          label="Business Hours End"
          inline
          type="time"
          helperText="Specify the time after which trips are considered to be after hours."
        />
      </div>
    </>
  );
}
