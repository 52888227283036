import { DateTime } from 'luxon';

import { convertClientDateTimeToServerISO } from 'src/common/date/convertClientDateTimeToServerISO';
import {
  PayerOrganizationRead,
  PaymentMethodEnum,
  TripLocationDetail,
  TripRecurrence,
  TripSubscriptionWrite,
} from 'src/common/external/bambi-api/bambiApi';

import { rRules } from '../constants';
import { FormValues } from './FormValues';

export default function generateTripSubscriptionFromFormValues(
  formValues: FormValues
): TripSubscriptionWrite {
  return {
    dispatcher_notes: formValues.dispatcher_notes,
    dropoff: {
      purpose: 'dropoff',
      address: formValues.dropoff.address.label,
      address_details: formValues.dropoff.address_details,
      place_id: formValues.dropoff.address.value,
      num_stairs: formValues.dropoff.stairs,
      stair_equipment: formValues.dropoff.stair_equipment,
      contact_name: formValues.dropoff.contact_name,
      contact_phone_number: formValues.dropoff.contact_phone_number,
      driver_notes: formValues.dropoff.driver_notes,
    } as TripLocationDetail,
    expires_at: formValues.expires_at,
    is_oxygen_required: formValues.service_details.is_oxygen_required,
    load_time_seconds: formValues.pickup.load_time_seconds || 0,
    must_provide_wheelchair: formValues.must_provide_wheelchair,
    num_accompanying_passengers:
      formValues.service_details.num_accompanying_passengers,
    num_attendants_needed: formValues.service_details.num_attendants_needed,
    oxygen_liters_per_min: formValues.service_details.oxygen_liters_per_min,
    passenger: {
      id: formValues.passenger.id,
    },
    payer: {
      id: formValues.pricing.payer || '',
    } as PayerOrganizationRead,
    payer_id: formValues.pricing.payer || '',
    payer_passenger_external_id: formValues.pricing.payer_passenger_external_id,
    payment_method: formValues.pricing.payment_method as PaymentMethodEnum,
    pickup: {
      purpose: 'pickup',
      address: formValues.pickup.address.label,
      address_details: formValues.pickup.address_details,
      place_id: formValues.pickup.address.value,
      num_stairs: formValues.pickup.stairs,
      stair_equipment: formValues.pickup.stair_equipment,
      contact_name: formValues.pickup.contact_name,
      contact_phone_number: formValues.pickup.contact_phone_number,
      driver_notes: formValues.pickup.driver_notes,
    } as TripLocationDetail,
    recurrences: generateTripSubscriptionRecurrencesFromFormValues(formValues),
    service_type: formValues.service_details.service_type,
    space_type: formValues.service_details.space_type,
    has_infectious_disease: formValues.service_details.has_infectious_disease,
    start_date: formValues.start_date,
    unload_time_seconds: formValues.dropoff.unload_time_seconds || 0,
    booked_by_name: formValues.booked_by_name,
    booked_by_phone_number: formValues.booked_by_phone_number,
    booked_by_phone_extension: formValues.booked_by_phone_extension,
  } as TripSubscriptionWrite;
}

const generateTripSubscriptionRecurrencesFromFormValues = (
  formValues: FormValues
): TripRecurrence[] => {
  const recurrences: TripRecurrence[] = [];

  formValues.recurrences.forEach((recurrence) => {
    recurrences.push({
      appointment_at: recurrence.appointment_time
        ? convertClientDateTimeToServerISO(
            DateTime.fromISO(recurrence.appointment_time)
          )
        : undefined,
      is_round_trip: false,
      is_will_call: recurrence.is_will_call || false,
      rrule:
        rRules[recurrence.day_of_the_week.toLowerCase() as keyof typeof rRules],
      scheduled_pickup_at: convertClientDateTimeToServerISO(
        DateTime.fromISO(recurrence.pickup_time)
      ),
      scheduled_pickup_time: recurrence.pickup_time,
      appointment_time: recurrence.appointment_time || undefined,
      input_price_cents: recurrence.input_price_cents || null,
    } as TripRecurrence);

    if (recurrence.is_round_trip) {
      recurrences.push({
        is_round_trip: true,
        is_will_call: recurrence.is_will_call || false,
        rrule:
          rRules[
            recurrence.day_of_the_week.toLowerCase() as keyof typeof rRules
          ],
        scheduled_pickup_at: recurrence.return_time
          ? convertClientDateTimeToServerISO(
              DateTime.fromISO(recurrence.return_time)
            )
          : undefined,
        scheduled_pickup_time: recurrence.return_time,
        input_price_cents: recurrence.return_input_price_cents || null,
      } as TripRecurrence);
    }
  });

  const earliestTripsPerDayOfWeek: Record<string, TripRecurrence> = {};
  recurrences.forEach((recurrence) => {
    const isFirstLeg = !recurrence.is_round_trip;
    if (isFirstLeg) {
      earliestTripsPerDayOfWeek[recurrence.rrule] = recurrence;
    }
  });

  recurrences.forEach((recurrence) => {
    const isSecondLeg = recurrence.is_round_trip;
    if (isSecondLeg && recurrence.is_will_call) {
      const firstLeg = earliestTripsPerDayOfWeek[recurrence.rrule];

      if (firstLeg && firstLeg.is_will_call) {
        firstLeg.is_will_call = false;
      }
    }
  });

  return recurrences;
};
