import { PriceSummaryTable } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/PriceSummaryTable';

import { PriceSummary } from '../external/bambi-api/bambiApi';
import { Button } from '../primitives/Button/Button';
import { Modal } from '../primitives/Modal/Modal';

interface PricingSummaryModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  pricingSummary?: PriceSummary | null;
}

export function PricingSummaryModal({
  open,
  onCancel,
  onConfirm,
  pricingSummary,
}: PricingSummaryModalProps) {
  return (
    <Modal
      open={open}
      setOpen={() => {}}
      contentClassnames="w-full max-w-[60vw]"
      data-testid="price-summary-modal"
    >
      {pricingSummary ? (
        <div className="flex flex-col gap-y-2">
          <PriceSummaryTable summary={pricingSummary} />
          <div className="flex justify-end gap-4 pt-4">
            <Button
              variant="secondary"
              onClick={onCancel}
              dataTestId="price-summary-modal-cancel-button"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirm}
              dataTestId="price-summary-modal-accept-button"
            >
              Accept
            </Button>
          </div>
        </div>
      ) : (
        <>
          Unable to generate quote. Check if pricing scheme exists for these
          trip details.
        </>
      )}
    </Modal>
  );
}
