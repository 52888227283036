import { useState } from 'react';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { Button } from 'src/common/primitives/Button';
import SecondsToMinutesField from 'src/common/SecondsToMinutesField';
import {
  AddressDetailsContextField,
  ContactNameContextField,
  ContactPhoneContextField,
  DriverNotesContextField,
  StairEquipmentContextField,
  StairsContextField,
} from 'src/features/add-trip/TripDetails/LocationDetailFields';

import { useApplyPassengerDispatchNotes } from '../useApplyPassengerDispatchNotes';
import DateField from './DateField';
import { DropoffAddressContextField } from './DropoffAddressContextField';
import { PickupAddressContextField } from './PickupAddressContextField';
import SubscriptionDaySelector from './SubscriptionDaySelector/SubscriptionDaySelector';
import TextField from './TextField';

export default function SubscriptionDetailsSection() {
  const form = useFormContext();
  const [showPickupDetails, setShowPickupDetails] = useState(false);
  const [showDropoffDetails, setShowDropoffDetails] = useState(false);

  useApplyPassengerDispatchNotes();

  return (
    <div className="flex w-full flex-col gap-y-4 rounded-md p-4 shadow-md ring-1 ring-inset ring-mint transition ease-in-out">
      <div className="flex">
        <TextField
          contextName="dispatcher_notes"
          rules={{ required: 'Dispatcher Notes required' }}
          label={'Dispatcher Notes (Used for Summary) *'}
          inputProps={{
            id: 'name',
            placeholder: 'Dispatcher Notes',
          }}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <DateField
            contextName={'start_date'}
            rules={{
              validate: (value: string) => {
                if (!value) {
                  return 'Start Date is required';
                }

                const expiresAt = form.getValues('expires_at');

                if (!expiresAt) return true;

                const diff = DateTime.fromISO(value).diff(
                  DateTime.fromISO(expiresAt)
                );

                return (
                  diff.milliseconds < 0 ||
                  'Start Date must be before Ends On date'
                );
              },
            }}
            label={'Start Date *'}
            inputProps={{
              id: 'start_date',
              min: DateTime.local().toISODate(),
            }}
          />
        </div>
        <div>
          <DateField
            contextName={'expires_at'}
            rules={{
              validate: (value: string) => {
                if (!value) {
                  return 'End Date is required';
                }

                const startDate = form.getValues('start_date');

                if (!startDate) return true;

                const diff = DateTime.fromISO(value).diff(
                  DateTime.fromISO(startDate)
                );

                if (diff.milliseconds > 0) {
                  form.clearErrors('start_date');
                }

                return true;
              },
            }}
            label={'Ends On *'}
            inputProps={{ id: 'ends_on', min: DateTime.local().toISODate() }}
          />
        </div>
      </div>
      <div>
        <SubscriptionDaySelector />
      </div>
      <div className="flex items-end gap-4">
        <PickupAddressContextField />
        <AddressDetailsContextField fieldPath="pickup" />
        <div>
          <Button
            className="flex justify-start gap-2"
            variant="secondary"
            onClick={() => {
              setShowPickupDetails(!showPickupDetails);
            }}
            justifyContent="start"
          >
            {showPickupDetails ? (
              <MinusCircleIcon className="h-5 w-5" />
            ) : (
              <PlusCircleIcon className="h-5 w-5" />
            )}
            Pickup Details
          </Button>
        </div>
      </div>
      {showPickupDetails && (
        <div className="flex gap-4">
          <div className="grow">
            <StairsContextField fieldPath="pickup" />
          </div>
          <div className="grow">
            <StairEquipmentContextField fieldPath="pickup" />
          </div>
          <div className="grow">
            <DriverNotesContextField fieldPath="pickup" />
          </div>
          <div className="grow">
            <ContactNameContextField fieldPath="pickup" />
          </div>
          <div className="grow">
            <ContactPhoneContextField fieldPath="pickup" />
          </div>
          <div className="grow">
            <SecondsToMinutesField
              fieldPath="pickup.load_time_seconds"
              label="Load Time (Minutes)"
            />
          </div>
        </div>
      )}

      <div className="flex items-end gap-4">
        <DropoffAddressContextField />
        <AddressDetailsContextField fieldPath="dropoff" />
        <div className="">
          <Button
            className="flex justify-start gap-2"
            variant="secondary"
            onClick={() => {
              setShowDropoffDetails(!showDropoffDetails);
            }}
            justifyContent="start"
          >
            {showDropoffDetails ? (
              <MinusCircleIcon className="h-5 w-5" />
            ) : (
              <PlusCircleIcon className="h-5 w-5" />
            )}
            Dropoff Details
          </Button>
        </div>
      </div>
      {showDropoffDetails && (
        <div className="flex gap-4">
          <div className="grow">
            <StairsContextField fieldPath="dropoff" />
          </div>
          <div className="grow">
            <StairEquipmentContextField fieldPath="dropoff" />
          </div>
          <div className="grow">
            <DriverNotesContextField fieldPath="dropoff" />
          </div>
          <div className="grow">
            <ContactNameContextField fieldPath="dropoff" />
          </div>
          <div className="grow">
            <ContactPhoneContextField fieldPath="dropoff" />
          </div>
          <div className="grow">
            <SecondsToMinutesField
              fieldPath="dropoff.unload_time_seconds"
              label="Load Time (Minutes)"
            />
          </div>
        </div>
      )}
    </div>
  );
}
