import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import { formatSimpleTime } from './formatSimpleTime';

export function TripTitle({
  trip,
}: {
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
}) {
  return (
    <div className="flex flex-wrap gap-1">
      <div className="break-word text-xs font-bold">
        {trip.passenger.full_name}
      </div>
      <div className="flex min-w-[100px] text-[.63rem]">
        {formatSimpleTime(trip.scheduled_pickup_at)} -{' '}
        {formatSimpleTime(trip.scheduled_dropoff_at)}
        {trip.appointment_at && ` (${formatSimpleTime(trip.appointment_at)})`}
      </div>
    </div>
  );
}
