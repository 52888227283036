import { Alert } from '../Alert/Alert';
import { Button } from '../primitives/Button';
import { Modal } from '../primitives/Modal';

interface PricingSummaryErrorModalProps {
  priceQuoteError?: string;
  onConfirm: () => void;
}

export function PricingSummaryErrorModal({
  priceQuoteError,
  onConfirm,
}: PricingSummaryErrorModalProps) {
  return (
    <Modal
      open={Boolean(priceQuoteError)}
      setOpen={() => {}}
      contentClassnames="w-full max-w-[33vw]"
      data-testid="price-summary-error-modal"
    >
      <div className="flex flex-col gap-2">
        <Alert
          title="Error fetching price quote"
          message={priceQuoteError as string}
          severity="error"
        />
        <div className="flex justify-end gap-4 pt-4">
          <Button
            variant="primary"
            onClick={onConfirm}
            dataTestId="price-summary-error-modal-ok-button"
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}
