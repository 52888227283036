import { Route, Routes } from 'react-router';

import { CredentialRouteActions } from 'src/common/credentials/CredentialRouteActions';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { AssignmentManagementRouteActions } from './AssignmentManagementRouteActions';
import { AttendantRouteActions } from './attendant/AttendantRouteActions';
import { DriverProfileRouteActions } from './DriverProfileRouteActions';
import { ManagementRouteActions } from './ManagementRouteActions';

export function TeamRouteActions() {
  const shouldUseRideOrderingPortalRoutes =
    useShouldShowRideOrderingPortalFeatures();

  if (shouldUseRideOrderingPortalRoutes) {
    return (
      <Routes>
        <Route index element={<ManagementRouteActions />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route index element={<AssignmentManagementRouteActions />} />
      <Route path="driver-profile" element={<DriverProfileRouteActions />} />
      <Route path="accounts" element={<ManagementRouteActions />} />
      <Route path="attendant-profile" element={<AttendantRouteActions />} />
      <Route path="member-credentials" element={<CredentialRouteActions />} />
    </Routes>
  );
}
