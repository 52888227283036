import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';

import { ReportsNav } from './ReportsNav';

export function DriverReportsRoute() {
  return (
    <ToolbarAndContentLayout
      preContent={<ReportsNav />}
      content={<ReportsContent />}
    />
  );
}

function ReportsContent() {
  return <div>Driver Reports</div>;
}
