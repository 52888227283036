import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import {
  CredentialRead,
  PatchedCredential,
  useCredentialsCreateMutation,
  useCredentialsDestroyMutation,
  useCredentialsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { CredentialFormValues } from './CredentialsFormValues';
import { generateCreateSuccessToast } from './generateCreateSuccessToast';
import { generateUpdateSuccessToast } from './generateUpdateSuccessToast';

export const useCredentialFormSubmit = (onClose: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const dispatch = useDispatch();
  const [createCredential] = useCredentialsCreateMutation();
  const [updateCredential] = useCredentialsPartialUpdateMutation();
  const [deleteCredential] = useCredentialsDestroyMutation();

  const onSubmit = async (values: CredentialFormValues) => {
    setIsSubmitting(true);
    setError(null);

    try {
      if (!values.id) {
        await createCredential({
          credential: values as unknown as CredentialRead,
        }).unwrap();
        dispatch(show(generateCreateSuccessToast(nextToastId)));
      } else {
        await updateCredential({
          id: values.id,
          patchedCredential: values as PatchedCredential,
        }).unwrap();
        dispatch(show(generateUpdateSuccessToast(nextToastId)));
      }
      onClose();
    } catch (error) {
      const msg = formatServerError(error);
      setError(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async (id: string) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await deleteCredential({ id }).unwrap();
      onClose();
    } catch (error) {
      const msg = formatServerError(error);
      setError(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { onSubmit, onDelete, isSubmitting, error, setError };
};
