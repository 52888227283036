export function MultiloadIcon() {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#F0FDF9" />
      <rect
        x="4"
        y="4.5"
        width="48"
        height="48"
        rx="24"
        stroke="#4DA8A3"
        strokeOpacity="0.2"
        strokeWidth="8"
      />
      <path
        d="M38 37.5V35.5C38 33.6362 36.7252 32.0701 35 31.626M31.5 19.7908C32.9659 20.3841 34 21.8213 34 23.5C34 25.1787 32.9659 26.6159 31.5 27.2092M33 37.5C33 35.6362 33 34.7044 32.6955 33.9693C32.2895 32.9892 31.5108 32.2105 30.5307 31.8045C29.7956 31.5 28.8638 31.5 27 31.5H24C22.1362 31.5 21.2044 31.5 20.4693 31.8045C19.4892 32.2105 18.7105 32.9892 18.3045 33.9693C18 34.7044 18 35.6362 18 37.5M29.5 23.5C29.5 25.7091 27.7091 27.5 25.5 27.5C23.2909 27.5 21.5 25.7091 21.5 23.5C21.5 21.2909 23.2909 19.5 25.5 19.5C27.7091 19.5 29.5 21.2909 29.5 23.5Z"
        stroke="#0CACA6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
