import { createSlice } from '@reduxjs/toolkit';

import {
  LocationLogEntryMinimalReadOnlyRead,
  SignatureRead,
  TripCreateRead,
  TripEventRead,
  TripMessageRead,
  TripUpdateRead,
} from 'src/common/external/bambi-api/bambiApi';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface ITripSummaryState {
  currentTripSummaryId?: string | null;
  tripEvents: TripEventRead[];
  tripCreateEvents: TripCreateRead[];
  tripUpdateEvents: TripUpdateRead[];
  tripLocations: LocationLogEntryMinimalReadOnlyRead[];
  isMapStyleLoaded: boolean;
  tripMessages: TripMessageRead[];
  tripSignatures: SignatureRead[];
}

export const initialState: ITripSummaryState = {
  tripEvents: [],
  tripCreateEvents: [],
  tripUpdateEvents: [],
  tripLocations: [],
  isMapStyleLoaded: false,
  tripMessages: [],
  tripSignatures: [],
};

export const tripSummarySlice = createSlice({
  name: 'tripSummary',
  initialState,
  reducers: {
    selectTripSummary: (state, action: PayloadAction<string>) => {
      state.currentTripSummaryId = action.payload;
    },
    deselectTripSummary: (state) => {
      state.currentTripSummaryId = null;
    },
    loadTripEvents: (state, action: PayloadAction<TripEventRead[]>) => {
      state.tripEvents = action.payload;
    },
    loadTripCreateEvents: (state, action: PayloadAction<TripCreateRead[]>) => {
      state.tripCreateEvents = action.payload;
    },
    loadTripUpdateEvents: (state, action: PayloadAction<TripUpdateRead[]>) => {
      state.tripUpdateEvents = action.payload;
    },
    loadTripLocations: (
      state,
      action: PayloadAction<LocationLogEntryMinimalReadOnlyRead[]>
    ) => {
      state.tripLocations = action.payload;
    },
    loadTripMessages: (state, action: PayloadAction<TripMessageRead[]>) => {
      state.tripMessages = action.payload;
    },
    loadTripSignatures: (state, action: PayloadAction<SignatureRead[]>) => {
      state.tripSignatures = action.payload;
    },
    onMapStyleLoaded: (state) => {
      state.isMapStyleLoaded = true;
    },
  },
});
