import { useEffect, useState } from 'react';

import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

import {
  PricingSchemeRead,
  PricingSchemeWrite,
  usePricingPricingSchemeConflictsCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormCancelButton } from 'src/common/FormCancelButton';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { SimpleContextFormField } from 'src/common/FormField/SimpleContextFormField';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { MultiSelectSpaceTypeField } from 'src/common/SpaceTypeField';
import formatServerError from 'src/common/util/serverErrorFormatter';

export type PricingSchemeWriteKeysForStepOne =
  | 'name'
  | 'description'
  | 'start_date'
  | 'end_date'
  | 'space_types'
  | 'payer_ids';

export type SchemeMetaFormValues = Pick<
  PricingSchemeWrite,
  PricingSchemeWriteKeysForStepOne
>;

export type PricingWizardSchemeMetaStepProps = {
  initialValues?: SchemeMetaFormValues;
  onCancel: () => void;
  onSubmit: (values: SchemeMetaFormValues) => void;
  originalPricingSchemeValues?: PricingSchemeRead;
};

export const defaultValues: SchemeMetaFormValues = {
  name: '',
  payer_ids: [],
  space_types: [],
  start_date: '',
  end_date: '',
  description: '',
};

export function PricingWizardSchemeMetaStep({
  originalPricingSchemeValues,
  initialValues,
  onCancel,
  onSubmit,
}: PricingWizardSchemeMetaStepProps) {
  const methods = useForm<SchemeMetaFormValues>({
    defaultValues: originalPricingSchemeValues
      ? pick(
          originalPricingSchemeValues,
          Object.keys(defaultValues).filter((key) => key !== 'payers')
        )
      : initialValues || defaultValues,
  });
  const [checkForSchemeConflicts] =
    usePricingPricingSchemeConflictsCreateMutation();
  const [formError, setFormError] = useState<string | null>(null);

  const validateDates = (startDate: string, endDate: string) => {
    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);

    if (start >= end) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (originalPricingSchemeValues) {
      methods.setValue(
        'payer_ids',
        originalPricingSchemeValues.payers.map((payer) => payer.payer)
      );
    }
  }, [methods, originalPricingSchemeValues]);
  return (
    <div data-testid="pricing-wizard-base-config" className="mt-6">
      <FormProvider {...methods}>
        <form
          data-testid="pricing-wizard-base-config-form"
          onSubmit={methods.handleSubmit(async (values) => {
            try {
              const checkForSchemeConflictsResponse =
                await checkForSchemeConflicts({
                  priceSchemeConflict: {
                    id: originalPricingSchemeValues?.id || undefined,
                    name: values.name,
                    start_date: values.start_date,
                    end_date: values.end_date,
                    payer_ids: values.payer_ids,
                    space_types: values.space_types || ([] as string[]),
                  },
                });

              if ('error' in checkForSchemeConflictsResponse) {
                return setFormError(
                  formatServerError(checkForSchemeConflictsResponse.error)
                );
              }

              if (
                'data' in checkForSchemeConflictsResponse &&
                checkForSchemeConflictsResponse.data.conflicts.length > 0
              ) {
                return setFormError(
                  `Conflicts found: ${checkForSchemeConflictsResponse.data.conflicts.join(
                    ', '
                  )}`
                );
              }
              onSubmit(values);
            } catch (e) {
              setFormError(formatServerError(e));
            }
          })}
        >
          <div className="flex max-w-xl flex-col gap-2 sm:min-w-[450px]">
            {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
            <SimpleContextFormField
              fieldPath="name"
              label="Name"
              rules={{
                required: 'Name is required',
              }}
              inline
              helperText="The name of the pricing scheme."
            />
            <MultiSelectPayerField
              fieldPath="payer_ids"
              rules={{
                required: 'Payers are required',
              }}
              inline
              helperText="The payers the pricing scheme will apply to."
            />
            <MultiSelectSpaceTypeField
              fieldPath="space_types"
              inline
              rules={{
                required: 'Must select at least 1 space type',
              }}
              helperText="The space types the pricing scheme will apply to."
            />
            <SimpleContextFormField
              fieldPath="start_date"
              label="Start Date"
              inline
              type="date"
              rules={{
                required: 'Start date is required',
                validate: (value) => {
                  const endDate = methods.getValues('end_date');
                  if (endDate && value && !validateDates(value, endDate)) {
                    methods.setError('end_date', {
                      message: 'End date must be after start date',
                    });
                  } else if (endDate) {
                    methods.clearErrors('end_date');
                  }
                  return true;
                },
              }}
              helperText="The date the pricing scheme will start."
            />
            <SimpleContextFormField
              fieldPath="end_date"
              label="End Date"
              inline
              type="date"
              rules={{
                required: 'End date is required',
                validate: (value) => {
                  const startDate = methods.getValues('start_date');
                  if (startDate && value && !validateDates(startDate, value)) {
                    return 'End date must be after start date';
                  }
                  return true;
                },
              }}
              helperText="The date the pricing scheme will end."
            />
            <SimpleContextFormField
              fieldPath="description"
              label="Description"
              inline
              type="textarea"
              helperText="A description of the pricing scheme."
            />
            <div className="mt-4 flex justify-between gap-2">
              <FormCancelButton
                onCancel={() => {
                  onCancel();
                  methods.reset();
                }}
                className="flex-grow"
              />
              <FormSubmitButton className="flex-grow" />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
