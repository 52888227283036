import { isNil } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';
import {
  execTripMenuAction,
  onConfirmAssignTrip,
  onRequestedTripAssignment,
} from 'src/features/dispatch/dispatcher.slice';

import { useRemoveTripFromMultiLoad } from '../DispatchCalendar/useRemoveTripFromMultiLoad';

export function useAssignTripFlow() {
  const {
    isAssignTripModalOpen,
    tripId,
    assignmentId,
    validateAssignmentResponse,
    status,
  } = useAppSelector((state) => state.dispatcher.tripAssignmentFlow);
  const dispatchResponse = useAppSelector(
    (state) => state.dispatcher.dispatchResponse
  );
  const dispatch = useAppDispatch();
  const tripRepository = useTripRepository();
  const removeTripFromMultiLoad = useRemoveTripFromMultiLoad();

  const onConfirm = () => {
    if (status === 'requested') {
      dispatch(
        onRequestedTripAssignment({
          tripId,
          assignmentId,
        })
      );
      // Trip accept confirm handles the rest, so we're done here
      dispatch(onConfirmAssignTrip());
      return;
    }

    dispatch(
      execTripMenuAction({
        action: async () => {
          const trip = await tripRepository.getTrip(tripId);
          const isUnassignedTrip = isNil(trip.assignment);
          const action = isUnassignedTrip
            ? tripRepository.assign
            : tripRepository.reassign;
          await action(tripId, assignmentId);
          // TODO: Haven't added multi-load info to /trips/ endpoints yet
          const multiLoadId = dispatchResponse?.assigned_trips.find(
            (t) => t.id === tripId
          )?.multiload_id;
          if (!isUnassignedTrip && typeof multiLoadId === 'string') {
            await removeTripFromMultiLoad(tripId, multiLoadId);
          }
          dispatch(onConfirmAssignTrip());
        },
        successMessage: 'Trip assigned',
        errorMessage: 'Error assigning trip',
      })
    );
  };

  return {
    isAssignTripModalOpen,
    validateAssignmentResponse,
    onConfirm,
  };
}
