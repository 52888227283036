import { useContext, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';
import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { DynamicFieldNameRootContext } from './DynamicFieldNameRootContext';

export function useApplyPassengerDispatchNotes() {
  const { getValues, setValue, formState } = useFormContext();
  const { dirtyFields } = formState;
  const { isRideOrderingPortalUser } = useUserRoles();

  const fieldNameRoot = useContext(DynamicFieldNameRootContext);

  const passenger: PassengerRead | null | undefined = useAppSelector(
    (state) => state.addATrip.selectedPassenger
  );

  const tripDispatcherNotesDirty = dirtyFields.trips?.some(
    (trip: any) => !!trip.dispatcher_notes
  );

  useEffect(() => {
    const id = getValues('id');

    const allowUpdate =
      !isRideOrderingPortalUser && !id && !tripDispatcherNotesDirty;

    if (allowUpdate) {
      setValue(
        `${fieldNameRoot}.dispatcher_notes`,
        passenger?.dispatcher_notes
      );
    }
  }, [
    dirtyFields,
    fieldNameRoot,
    getValues,
    isRideOrderingPortalUser,
    passenger?.dispatcher_notes,
    setValue,
    tripDispatcherNotesDirty,
  ]);
}
