import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { RunBambiRunTripRead } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { SelectOption } from 'src/common/primitives/Select';
import { ToggleGroup } from 'src/common/ToggleGroup';

import { dispatcherSlice, onApplyRunBambiRun } from '../../dispatcher.slice';
import { TripAcceptRequestModal } from '../../modals/requestedTrips/accept/TripAcceptRequestModal';
import { useAcceptTripRequestConfirmModalProps } from '../../modals/requestedTrips/accept/useAcceptTripRequestConfirmModalProps';
import { NewAssignmentsList } from './NewAssignmentsList';
import { NotAssignedList } from './NotAssignedList';
import { ReassignmentList } from './ReassignmentList';

const ASSIGNMENT_TABS: SelectOption[] = [
  'New Assignments',
  'Reassignments',
  'Not Assigned',
].map((tab) => {
  return {
    label: tab,
    value: tab,
  };
});
export function RunBambiRunReview() {
  const {
    runBambiRunResult,
    tripAssignmentsToApplyIds,
    runBambiRunError,
    selectedDate,
  } = useAppSelector((state: RootState) => state.dispatcher);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rbrJobId } = useParams();
  const [currentTab, setCurrentTab] = useState(ASSIGNMENT_TABS[0]);
  const [runBambiRunApplyError, setRunBambiRunApplyError] = useState('');
  const tripAcceptRequestModalProps = useAcceptTripRequestConfirmModalProps();
  if (!runBambiRunResult) return null;

  return (
    <>
      <ToggleGroup
        options={ASSIGNMENT_TABS}
        value={currentTab.value}
        onChange={(newValue) =>
          setCurrentTab(
            ASSIGNMENT_TABS.find((tab) => newValue === tab.value) ||
              ASSIGNMENT_TABS[0]
          )
        }
      />
      {currentTab.value === 'New Assignments' && <NewAssignmentsList />}
      {currentTab.value === 'Reassignments' && <ReassignmentList />}
      {currentTab.value === 'Not Assigned' && <NotAssignedList />}
      <div
        className={`flex flex-col items-center justify-center gap-2.5 rounded-[10px] bg-white py-2 px-5 text-center font-normal text-[rgba(51,51,51,1)] drop-shadow-lg`}
      >
        {!!runBambiRunApplyError && (
          <div className="w-full text-left">
            <FormErrorMessage>{runBambiRunApplyError}</FormErrorMessage>
          </div>
        )}
        {!!runBambiRunError && (
          <div className="w-full text-left">
            <FormErrorMessage>{runBambiRunError}</FormErrorMessage>
          </div>
        )}
        <div className="flex w-full items-center justify-between gap-4">
          <Button
            variant="primary-danger"
            className="flex-grow"
            onClick={() => {
              // TODO: Do we cancel the job on the backend or just leave it?
              dispatch(dispatcherSlice.actions.onCancelRunBambiRun());
              navigate(`/dispatch/${selectedDate}`);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="flex-grow"
            disabled={Boolean(runBambiRunError)}
            onClick={async () => {
              const hasRequestedTripsToApply =
                runBambiRunResult.new_trip_assignments
                  .filter((trip) => tripAssignmentsToApplyIds[trip.id])
                  .some((trip) => trip.status === 'requested');
              if (hasRequestedTripsToApply) {
                dispatch(
                  dispatcherSlice.actions.onApplyRunBambiRunWithRequestedTrips()
                );
              } else {
                if (typeof rbrJobId !== 'string') {
                  setRunBambiRunApplyError('No job id found');
                  return;
                }
                setRunBambiRunApplyError('');
                try {
                  await dispatch(
                    onApplyRunBambiRun({
                      rbrJobId,
                    })
                  );
                  navigate(`/dispatch/${selectedDate}`);
                } catch (e) {
                  setRunBambiRunApplyError('Error applying Run Bambi Run');
                }
              }
            }}
          >
            Apply
          </Button>
          <TripAcceptRequestModal props={tripAcceptRequestModalProps} />
        </div>
      </div>
    </>
  );
}

export function getExcludedIds(
  assignments: RunBambiRunTripRead[],
  includedTripIdMap: { [k: string]: boolean }
): string[] {
  return assignments
    .filter((tripAssignment) => !includedTripIdMap[tripAssignment.id])
    .map((tripAssignment) => tripAssignment.id);
}
