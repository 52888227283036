import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';

import { ReportsNav } from './ReportsNav';

export function PayerReportsRoute() {
  return (
    <ToolbarAndContentLayout
      preContent={<ReportsNav />}
      content={<ReportsContent />}
    />
  );
}

function ReportsContent() {
  return <div>Payer Reports</div>;
}
