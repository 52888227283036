import { useAppDispatch, useAppSelector } from 'src/app/store';

import { dispatcherSlice } from '../dispatcher.slice';
import { TripListItem } from '../trips/TripListItem';

export function MultiloadAssignmentSelection() {
  const dispatch = useAppDispatch();
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );
  const assignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assigned_trips
  );
  const {
    originatingTripId,
    selectedTripIdsToMultiload,
    potentialMultiloadTrips,
    mode,
  } = useAppSelector((state) => state.dispatcher.multiloadAssignmentFlow);

  if (!(unassignedTrips && assignedTrips)) {
    return <div>No Trips Found</div>;
  }

  return (
    <ul className="flex flex-col gap-y-3">
      {[...unassignedTrips, ...assignedTrips]
        .filter(
          (t) =>
            potentialMultiloadTrips.includes(t.id) || t.id === originatingTripId
        )
        // Probably shouldn't use toSorted here yet, so create a new array to sort in place
        .map((t) => t)
        // Sort the trips so that the originating trip is always at the top
        .sort((a) => (a.id === originatingTripId ? -1 : 1))
        .map((trip) => (
          <li key={trip.id}>
            <TripListItem
              trip={trip}
              disabled={mode === 'assign-trip' && originatingTripId === trip.id}
              checked={selectedTripIdsToMultiload.includes(trip.id)}
              onCheckedChange={(val) => {
                dispatch(
                  dispatcherSlice.actions.onMultiloadAssignmentCheckedChange({
                    tripId: trip.id,
                    checked: val === true,
                  })
                );
              }}
              hideMenu
            />
          </li>
        ))}
    </ul>
  );
}
