export function getPriceV1CurrencyInputLabel(priceEstimationState?: {
  isEstimated: boolean;
  isStale: boolean;
  isManuallyUpdated: boolean;
}) {
  const baseLabel = 'Input Price';
  if (!priceEstimationState) return baseLabel;
  if (priceEstimationState.isEstimated) {
    return `${baseLabel} (Estimated)`;
  }
  if (priceEstimationState.isStale) {
    return `${baseLabel} (Stale)`;
  }
  if (priceEstimationState.isManuallyUpdated) {
    return `${baseLabel} (Manually Updated)`;
  }
  return baseLabel;
}
