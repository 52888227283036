import { TabNavigation } from 'src/common/TabNavigation';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { nonRideOrderingPortalTabs } from './nonRideOrderingPortalTabs';
import { rideOrderingPortalTabs } from './rideOrderingPortalTabs';
import { useNonRideOrderingPortalTabs } from './useNonRideOrderingPortalTabs';

export function TeamNav() {
  const nonRideOrderTabs = useNonRideOrderingPortalTabs(
    nonRideOrderingPortalTabs
  );

  const shouldShowRideOrderingNavBar =
    useShouldShowRideOrderingPortalFeatures();
  return (
    <TabNavigation
      tabs={
        shouldShowRideOrderingNavBar ? rideOrderingPortalTabs : nonRideOrderTabs
      }
    />
  );
}
