import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  SignatureRead,
  useTripsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryRouteParams } from '../../routes';

const badgeColors = {
  membership: 'primary',
  passenger: 'blue',
  guardian: 'yellow',
  facility: 'green',
  unable: 'red',
};

export function useSignatureDetails(tripSignature: SignatureRead) {
  const [signatureLocationType, setSignatureLocationType] = useState('');
  const { tripId } = useParams<TripSummaryRouteParams>();

  const { data: trip } = useTripsRetrieveQuery(
    { id: tripId as string },
    { skip: !tripId }
  );

  const badgeColor = tripSignature.signature_type
    ? badgeColors[tripSignature.signature_type] || 'gray'
    : 'gray';

  useEffect(() => {
    if (trip && tripSignature.signed_items[0]) {
      const signedItemId = tripSignature.signed_items[0].object_id;
      setSignatureLocationType(
        trip.pickup.id === signedItemId
          ? 'pickup'
          : trip.dropoff.id === signedItemId
          ? 'dropoff'
          : ''
      );
    }
  }, [trip, tripSignature.signed_items]);

  return { signatureLocationType, badgeColor };
}
