import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';

export function useApplyPassengerDispatchNotes() {
  const { getValues, setValue, formState } = useFormContext();
  const { dirtyFields } = formState;

  const passenger = useAppSelector(
    (state) => state.tripSubscription.selectedPassenger
  );

  useEffect(() => {
    const isDispatchNotesDirty = dirtyFields.dispatcher_notes;
    const id = getValues('id');

    if (!id && !isDispatchNotesDirty) {
      setValue('dispatcher_notes', passenger?.dispatcher_notes);
    }
  }, [dirtyFields, getValues, passenger?.dispatcher_notes, setValue]);
}
