import ProgressBarIndicator from 'src/common/primitives/ProgressBar/ProgressBarIndicator';

import { MetricCard } from './MetricCard';

type ProgressBarMetricCardProps = {
  title: string;
  formattedValue: string;
  progressPercentage: number;
  partLabel: string;
  totalLabel: string;
};

export function ProgressBarMetricCard({
  title,
  formattedValue,
  progressPercentage,
  partLabel,
  totalLabel,
}: ProgressBarMetricCardProps) {
  return (
    <MetricCard title={title} formattedValue={formattedValue}>
      <>
        <div
          className="align-center flex justify-between text-xs text-gray-400"
          data-testid="progress-label-container"
        >
          <div>{partLabel}</div>
          <div>{totalLabel}</div>
        </div>

        <ProgressBarIndicator progressPercentage={progressPercentage} />
      </>
    </MetricCard>
  );
}
