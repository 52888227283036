import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import { ProgressBar } from 'src/common/primitives/ProgressBar';

export function WizardHeader({
  title,
  wizardState,
  description,
  setStep,
}: {
  title: string;
  description?: string;
  wizardState?: { step: number; totalSteps: number };
  setStep?: (step: number) => void;
}) {
  return (
    <>
      <div className="flex justify-between">
        <h3 className="flex grow items-center gap-2 text-xl font-medium leading-6 text-gray-900">
          {wizardState && setStep && wizardState.step > 1 ? (
            <ArrowLeftIcon
              onClick={() => setStep(wizardState.step - 1)}
              className="font-heavy h-6 w-6 hover:cursor-pointer"
              data-testid="wizard-back-button"
            />
          ) : null}
          {title}
        </h3>
        {wizardState ? (
          <div className="flex w-[50%] items-center">
            <ProgressBar
              step={wizardState.step}
              total={wizardState.totalSteps}
            />
          </div>
        ) : null}
      </div>
      {description ? (
        <p className="mt-1 text-xs text-gray-500">{description}</p>
      ) : null}
    </>
  );
}
