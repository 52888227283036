import { createSlice } from '@reduxjs/toolkit';

import { VehicleRead } from 'src/common/external/bambi-api/bambiApi';
export interface IFleetState {
  vehicles: any[];
  vehicleSearchTerm: string;
  selectedVehicle: VehicleRead;
  vehicleCredentialsSearchTerm: string;
}

export const initialState: IFleetState = {
  vehicles: [],
  vehicleSearchTerm: '',
  selectedVehicle: {
    id: '',
    nickname: '',
    vin: '',
    organization_id: '',
    created_at: '',
    updated_at: '',
    make: '',
    model: '',
    color: '',
    year: null,
    can_be_scheduled: true,
    license_plate: '',
    category: 'ambulatory',
    notes: '',
    storage_location: {
      label: '',
      place_id: '',
    },
    capacity_configurations: [],
    tags: [],
  },
  vehicleCredentialsSearchTerm: '',
};

export const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setSelectedVehicle: (state, action) => {
      const newSelectedVehicle = {
        ...state.selectedVehicle,
        ...action.payload,
      };
      state.selectedVehicle = newSelectedVehicle;
    },
    clearSelectedVehicle: (state) => {
      state.selectedVehicle = initialState.selectedVehicle;
    },
    setVehicleSearchTerm: (state, action) => {
      state.vehicleSearchTerm = action.payload;
    },
    setVehicleCredentialsSearchTerm: (state, action) => {
      state.vehicleCredentialsSearchTerm = action.payload;
    },
  },
});

export const {
  setVehicles,
  setSelectedVehicle,
  clearSelectedVehicle,
  setVehicleSearchTerm,
  setVehicleCredentialsSearchTerm,
} = fleetSlice.actions;
