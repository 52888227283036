import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useFeature } from 'src/app/FeatureToggle';

import { DefaultRouteLayout } from '../dispatch';

export function ReportsLayout() {
  const reportsFeatureState = useFeature('reporting');
  const navigate = useNavigate();

  useEffect(() => {
    if (!(reportsFeatureState.isLoading || reportsFeatureState.isEnabled)) {
      navigate('/');
    }
  }, [reportsFeatureState.isEnabled, navigate, reportsFeatureState.isLoading]);

  return <DefaultRouteLayout />;
}
