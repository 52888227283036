import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/app/store';

import { MultiloadPickupDropoffOrderItem } from './MultiloadOrderStep';

export function useMultiloadOrderItems() {
  const [orderedMultiLoadItems, setOrderedMultiLoadItems] = useState<
    MultiloadPickupDropoffOrderItem[]
  >([]);
  const { selectedTripIdsToMultiload, originatingTripId } = useAppSelector(
    (state) => state.dispatcher.multiloadAssignmentFlow
  );
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );
  const assignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assigned_trips
  );

  useEffect(() => {
    if (
      !(unassignedTrips && assignedTrips) ||
      // Once we have the initial order, don't reorder except on user action
      orderedMultiLoadItems.length > 0
    ) {
      return;
    }

    const itemsToMultiload = [...unassignedTrips, ...assignedTrips].filter(
      (trip) =>
        selectedTripIdsToMultiload.includes(trip.id) ||
        trip.id === originatingTripId
    );
    const orderItems: MultiloadPickupDropoffOrderItem[] = [];
    itemsToMultiload.forEach((trip) => {
      orderItems.push({
        id: trip.id + '-pickup',
        trip,
        type: 'pickup',
        scheduledAt: trip.scheduled_pickup_at,
        order: trip.pickup_order,
      });
      orderItems.push({
        id: trip.id + '-dropoff',
        trip,
        type: 'dropoff',
        scheduledAt: trip.scheduled_dropoff_at,
        order: trip.dropoff_order,
      });
    });
    orderItems.sort((a, b) => {
      if (typeof a.order === 'number' && typeof b.order === 'number') {
        return a.order - b.order;
      }
      // Sort items with an order first
      if (typeof a.order === 'number' && typeof b.order !== 'number') {
        return 1;
      }
      if (typeof a.order !== 'number' && typeof b.order === 'number') {
        return -1;
      }
      return (
        new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime()
      );
    });
    setOrderedMultiLoadItems(orderItems);
  }, [
    unassignedTrips,
    assignedTrips,
    selectedTripIdsToMultiload,
    originatingTripId,
    orderedMultiLoadItems,
  ]);

  return { orderedMultiLoadItems, setOrderedMultiLoadItems };
}
