import { DateTime, Interval } from 'luxon';

import {
  AssignmentRead,
  DispatchTripRead,
  PayerOrganizationRead,
} from 'src/common/external/bambi-api/bambiApi';

const TIME_WINDOW_MINUTES = 15;

export function getPotentialMultiloadTripIds(
  originatingTrip: DispatchTripRead,
  otherTrips: DispatchTripRead[],
  assignment: AssignmentRead,
  payers: PayerOrganizationRead[],
  multiLoadId?: string
): string[] {
  if (otherTrips.length < 1 || payers.length < 1) {
    return [];
  }

  const originatingTripPayer = payers.find(
    (payer) => payer.id === originatingTrip.payer.id
  );

  if (!(originatingTripPayer && originatingTripPayer.can_multiload)) {
    return [];
  }

  const timeInterval = createMultiLoadTimeInterval(
    originatingTrip.scheduled_pickup_at,
    originatingTrip.scheduled_dropoff_at
  );

  return otherTrips
    .filter((trip) => {
      if (trip.id === originatingTrip.id || trip.id.includes('preview')) {
        return false;
      }

      if (trip.assignment_id && trip.assignment_id !== assignment.id) {
        return false;
      }

      if (
        typeof multiLoadId === 'string' &&
        trip.multiload_id === multiLoadId
      ) {
        return true;
      }

      const isSamePayerOrHasAgreement =
        trip.payer.id === originatingTrip.payer.id ||
        originatingTripPayer.multiloading_partners?.includes(trip.payer.id);

      if (!isSamePayerOrHasAgreement) {
        return false;
      }

      return (
        timeInterval.contains(DateTime.fromISO(trip.scheduled_pickup_at)) ||
        timeInterval.contains(DateTime.fromISO(trip.scheduled_dropoff_at))
      );
    })
    .map((trip) => trip.id);
}

function createMultiLoadTimeInterval(
  start: string,
  end: string,
  bufferMinutes = TIME_WINDOW_MINUTES
) {
  return Interval.fromDateTimes(
    DateTime.fromISO(start).minus({ minutes: bufferMinutes }),
    DateTime.fromISO(end).plus({ minutes: bufferMinutes })
  );
}
