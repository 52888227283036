import { ServiceTypeEnum } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export const serviceTypeOptions: SelectOption[] = [
  {
    label: 'Curb to Curb',
    value: 'curb-to-curb',
  },
  {
    label: 'Door to Door',
    value: 'door-to-door',
  },
  {
    label: 'Door Through Door',
    value: 'door-through-door',
  },
];

export const serviceTypeOptionsMap: Record<ServiceTypeEnum, string> =
  serviceTypeOptions.reduce((acc, option) => {
    return {
      ...acc,
      [option.value]: option.label,
    };
  }, {} as Record<ServiceTypeEnum, string>);
