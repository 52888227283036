import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';

import {
  setEventCalendarScrollable,
  setShowToggleCalendarScrollButton,
} from '../dispatcher.slice';

const EXPANDED_ASSIGNMENT_COLUMN_WIDTH = 200;

const baseCss = `
        .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header, .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
          background-color: white;
        }
        .mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day, .mbsc-ios.mbsc-schedule-wrapper {
          background-color: white;
        }
        .mbsc-ios.mbsc-schedule-date-header {
          display: none;
        }

        .mbsc-schedule-resource-title {
          padding: 0;
        }
        .mbsc-schedule-resource-group.mbsc-ltr,
        .mbsc-ios.mbsc-schedule-resource.mbsc-ltr {
          border-left: none;
        }

        .mbsc-ios.mbsc-schedule-time-indicator {
          /* Volcano, apparently */
           border-color: #FF7A45;
        }

        .mbsc-ios.mbsc-schedule-cursor-time, .mbsc-ios.mbsc-schedule-time-indicator-time {
          color: #FF7A45;
        }

        .mbsc-ios.mbsc-schedule-invalid {
          background-color: #E5E7EB;
        }

        .mbsc-schedule-header-item {
          display: none;
        }

        /* min-width needs to be set for column expansion to work */
        /* 
          200px as min-width breaks what users call "fit to screen" for assignments 
          so this reverts the behavior but maintains the column expansion animation 
          Once this is out I'll start playing around with a better solution to
          compromise
        */
        .mbsc-schedule-col-width {
          min-width: 1px;
        }
      `;

const scrollableCss = `
       /* Based on forum post: https://forum.mobiscroll.com/t/event-calendar-resource-fixed-width-or-scroll/1645/3 */
        
        div.mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
          height: .5em
        }

         div.mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }

        .mbsc-schedule-col-width {
          width: ${EXPANDED_ASSIGNMENT_COLUMN_WIDTH}px;
        }
      `;

const useDynamicCss = (calendar: MutableRefObject<any>) => {
  const dispatch = useAppDispatch();
  const { isEventCalendarScrollable, dispatchResponse, isDispatchSidebarOpen } =
    useAppSelector((state) => state.dispatcher);

  const [activeCss, setActiveCss] = useState(baseCss);

  const calculateShouldShowToggle = useCallback(() => {
    if (calendar.current?.nativeElement instanceof HTMLElement) {
      const calendarBodyWidth = calendar.current.nativeElement.querySelector(
        '.mbsc-schedule-grid-wrapper'
      ).clientWidth;

      const assignmentsWidth = dispatchResponse?.assignments.length
        ? dispatchResponse.assignments.length * EXPANDED_ASSIGNMENT_COLUMN_WIDTH
        : 0;

      return calendarBodyWidth - assignmentsWidth < 0;
    }
  }, [calendar, dispatchResponse?.assignments.length]);

  useEffect(() => {
    setActiveCss(isEventCalendarScrollable ? baseCss + scrollableCss : baseCss);
  }, [isEventCalendarScrollable]);

  useEffect(() => {
    const updateStateFromWidth = () => {
      const shouldShowToggle = calculateShouldShowToggle();
      dispatch(setShowToggleCalendarScrollButton(shouldShowToggle));
      if (!shouldShowToggle) {
        dispatch(setEventCalendarScrollable(false));
      }
    };

    const handleResize = () => {
      updateStateFromWidth();
    };

    window.addEventListener('resize', handleResize);
    updateStateFromWidth();

    return () => window.removeEventListener('resize', handleResize);
  }, [
    dispatch,
    dispatchResponse?.assignments.length,
    calculateShouldShowToggle,
    isDispatchSidebarOpen,
  ]);

  return activeCss;
};

export default useDynamicCss;
