import {
  SignatureRead,
  TripCreateRead,
  TripEventRead,
  TripMessageRead,
  TripUpdateRead,
} from 'src/common/external/bambi-api/bambiApi';

export function resolveAccordionValue(
  tripCreateEvents: TripCreateRead[],
  tripEvents: TripEventRead[],
  tripUpdateEvents: TripUpdateRead[],
  tripMessages: TripMessageRead[],
  tripSignatures: SignatureRead[],
  selectedTripEventId: string | null | undefined
) {
  const resolvedSelectedTripEvent = tripEvents.find(
    (x) => x.id === selectedTripEventId
  );

  if (resolvedSelectedTripEvent) {
    return resolvedSelectedTripEvent.id;
  }

  const resolvedCreateTripEventId =
    tripCreateEvents
      .find((x) => x.id === Number(selectedTripEventId))
      ?.id.toString() || '';

  if (resolvedCreateTripEventId) {
    return resolvedCreateTripEventId;
  }

  const foundTripMessage = tripMessages.find(
    (message) => `message-${message.id}` === selectedTripEventId
  );

  if (foundTripMessage) {
    return `message-${foundTripMessage.id}`;
  }

  const foundTripSignature = tripSignatures.find(
    (signature) => `signature-${signature.id}` === selectedTripEventId
  );

  if (foundTripSignature) {
    return `signature-${foundTripSignature.id}`;
  }

  return (
    tripUpdateEvents
      .find((x) => x.id === Number(selectedTripEventId))
      ?.id.toString() || ''
  );
}
