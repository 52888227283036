import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  PriceSummary,
  usePricingPriceQuoteCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { useFormValues } from 'src/common/useFormValues';
import formatServerError from 'src/common/util/serverErrorFormatter';

export function useTripSubscriptionInputPriceV1ViewModel(
  name: string,
  index: number
) {
  const { setValue } = useFormContext();
  const fieldName = `recurrences.${index}.${name}`;
  const [priceQuoteError, setPriceQuoteError] = useState<string | undefined>();
  const [priceSummaryResult, setPriceSummaryResult] = useState<
    PriceSummary | undefined
  >();

  const [dayPriceEstimationState, setDayPriceEstimationState] = useState<{
    isEstimated: boolean;
    isStale: boolean;
    isManuallyUpdated: boolean;
  }>({
    isEstimated: false,
    isStale: false,
    isManuallyUpdated: false,
  });

  const [isQuoteSummaryOpen, setIsQuoteSummaryOpen] = useState(false);
  const [fetchQuote, quoteRequestState] = usePricingPriceQuoteCreateMutation();

  const {
    passenger,
    start_date,
    pickup,
    dropoff,
    must_provide_wheelchair,
    recurrences,
    pricing,
    service_details,
  } = useFormValues();

  const time =
    name === 'return_input_price_cents'
      ? recurrences[index].return_time
      : recurrences[index].pickup_time;

  const hasRequiredPricingFields = !!(
    typeof pricing.payer === 'string' &&
    pickup.address.value &&
    dropoff.address.value &&
    start_date &&
    time
  );

  const handleFetchQuoteClick = async () => {
    if (!hasRequiredPricingFields) return;
    try {
      const quoteResponse = await fetchQuote({
        priceQuote: {
          is_canceled: false,
          cancellation_reason: null,
          canceled_at: undefined,
          pickup_at: convertISODateTimePartsToUTCISO({
            date: start_date,
            time: time,
          }),
          pickup_place_id: pickup.address.value,
          dropoff_place_id: dropoff.address.value,
          wait_time_minutes: 0,
          steps_pickup: 0,
          steps_dropoff: 0,
          num_attendants: service_details.num_attendants_needed,
          num_additional_passengers:
            service_details.num_accompanying_passengers,
          is_bariatric: !!passenger.needs_bariatric_transport,
          is_wheelchair_provided: must_provide_wheelchair,
          is_oxygen_provided: service_details.is_oxygen_required,
          space_type: service_details.space_type,
          payer: pricing.payer,
          is_will_call: recurrences[index].is_will_call,
          has_infectious_disease: service_details.has_infectious_disease,
          is_trip_subscription: true,
        },
      });
      if ('error' in quoteResponse) {
        setPriceQuoteError(formatServerError(quoteResponse.error));
        return;
      }

      setPriceSummaryResult(quoteResponse.data.price_summary);
      setIsQuoteSummaryOpen(true);
    } catch (e) {
      setPriceQuoteError('An unknown error occurred');
    }
  };

  const pickupTime = recurrences[index]?.pickup_time;

  useEffect(() => {
    if (dayPriceEstimationState.isEstimated) {
      setDayPriceEstimationState({
        isEstimated: false,
        isStale: true,
        isManuallyUpdated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    start_date,
    pickup.address.value,
    dropoff.address.value,
    must_provide_wheelchair,
    recurrences,
    pricing,
    service_details.num_attendants_needed,
    service_details.num_accompanying_passengers,
    service_details.is_oxygen_required,
    service_details.space_type,
    service_details.has_infectious_disease,
    pricing.payer,
    passenger?.needs_bariatric_transport,
    pickupTime,
  ]);

  return {
    dayPriceEstimationState,
    fieldName,
    handleFetchQuoteClick,
    hasRequiredPricingFields,
    isQuoteSummaryOpen,
    priceQuoteError,
    priceSummaryResult,
    quoteRequestState,
    setDayPriceEstimationState,
    setIsQuoteSummaryOpen,
    setPriceQuoteError,
    setValue,
  };
}
