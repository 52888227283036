import { colors } from 'src/common/theme/tailwinds';

import { DonutChart } from '../metric-components/DonutChart';
import { MetricCard } from '../metric-components/MetricCard';
import { ProgressBarMetricCard } from '../metric-components/ProgressBarMetricCard';

const hardCodedDonutCellData: {
  id: string;
  name: string;
  value: number;
  backgroundColor: string;
}[] = [
  {
    id: 'canceled',
    name: 'Canceled',
    value: 400,
    backgroundColor: colors.trip.canceled.text,
  },
  {
    id: 'completed',
    name: 'Completed',
    value: 600,
    backgroundColor: colors.trip.complete.text,
  },
];

export default function ReportsContent() {
  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
      <MetricCard title="Estimated Revenue" formattedValue="$1,000,000" />
      <ProgressBarMetricCard
        title="Percent On-time"
        formattedValue="90%"
        progressPercentage={90}
        partLabel="900 trips on-time"
        totalLabel="1000 trips total"
      />
      <ProgressBarMetricCard
        title="Estimated Mileage"
        formattedValue="900 loaded / 100 unloaded"
        progressPercentage={90}
        partLabel="900 loaded miles"
        totalLabel="1000 total miles"
      />
      <MetricCard title="Trip Status">
        <DonutChart cells={hardCodedDonutCellData} />
      </MetricCard>
    </dl>
  );
}
