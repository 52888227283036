import { useEffect } from 'react';

import { useAppDispatch } from 'src/app/store';

import { onClearFilters, setCredentialSearchTerm } from './credential.slice';

export function useCredentialClearState() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setCredentialSearchTerm(''));
      dispatch(onClearFilters());
    };
  }, [dispatch]);
}
