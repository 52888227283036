import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { CurrencyInput } from 'src/common/CurrencyInput';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { getPriceV1CurrencyInputLabel } from 'src/common/pricing/getPriceV1CurrencyInputLabel';
import { PricingSummaryErrorModal } from 'src/common/pricing/PricingSummaryErrorModal';
import { PricingSummaryModal } from 'src/common/pricing/PricingSummaryModal';
import { Button } from 'src/common/primitives/Button/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { useTripSubscriptionInputPriceV1ViewModel } from './useTripSubscriptionInputPriceV1ViewModel';

interface TripSubscriptionInputPriceV1Props {
  name: string;
  index: number;
}

export function TripSubscriptionInputPriceV1({
  name,
  index,
}: TripSubscriptionInputPriceV1Props) {
  const { setValue } = useFormContext();

  const {
    dayPriceEstimationState,
    fieldName,
    handleFetchQuoteClick,
    hasRequiredPricingFields,
    isQuoteSummaryOpen,
    priceQuoteError,
    priceSummaryResult,
    quoteRequestState,
    setDayPriceEstimationState,
    setIsQuoteSummaryOpen,
    setPriceQuoteError,
  } = useTripSubscriptionInputPriceV1ViewModel(name, index);

  return (
    <FormContextField name={fieldName}>
      {({ field, fieldState }) => {
        const { onChange, ...remainingProps } = field;
        return (
          <DefaultFormFieldLayout
            label={getPriceV1CurrencyInputLabel(dayPriceEstimationState)}
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: name,
            }}
          >
            <div className="flex items-center gap-2">
              <CurrencyInput
                {...remainingProps}
                data-testid={fieldName}
                onValueChange={(values) => {
                  field.onChange(values.floatValue);
                }}
                onKeyDown={() => {
                  if (dayPriceEstimationState.isEstimated) {
                    setDayPriceEstimationState({
                      isEstimated: false,
                      isStale: false,
                      isManuallyUpdated: true,
                    });
                  }
                }}
                className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 shadow-sm disabled:cursor-not-allowed sm:text-sm"
              />

              <Button
                variant={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  dayPriceEstimationState?.isEstimated
                    ? 'secondary'
                    : 'primary-outline'
                }
                dataTestId={`recurrences-${index}-price-estimation-button-v1`}
                className="whitespace-nowrap"
                onClick={handleFetchQuoteClick}
                disabled={
                  quoteRequestState.isLoading || !hasRequiredPricingFields
                }
              >
                {quoteRequestState.isLoading ? (
                  <LoadingIndicator variant="small" />
                ) : (
                  <div title="Get Quote">
                    <CurrencyDollarIcon className="h-4 w-4" />
                  </div>
                )}
              </Button>
              <PricingSummaryModal
                open={isQuoteSummaryOpen}
                onCancel={() => setIsQuoteSummaryOpen(false)}
                onConfirm={() => {
                  // total can be null or undefined so we want to make sure
                  // we use 0. Otherwise the input won't update the value
                  setValue(fieldName, priceSummaryResult?.total ?? 0);
                  setIsQuoteSummaryOpen(false);
                  setDayPriceEstimationState({
                    isManuallyUpdated: false,
                    isEstimated: true,
                    isStale: false,
                  });
                }}
                pricingSummary={priceSummaryResult}
              />
              <PricingSummaryErrorModal
                priceQuoteError={priceQuoteError}
                onConfirm={() => setPriceQuoteError(undefined)}
              />
            </div>
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
