import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AttendantRead } from 'src/common/external/bambi-api/bambiApi';

import { DriverProfile } from './driver-profile/DriverProfile';
import { Person } from './management/Person';

export interface ITeamState {
  members: Person[];
  driverProfiles: DriverProfile[];
  teamSearchTerm: string;
  driverProfileSearchTerm: string;
  selectedDriverProfile: DriverProfile | null;
  showDriverProfileModal: boolean;
  selectedAttendantProfile: AttendantRead | null;
  attendantProfileSearchTerm: string;
  showAttendantProfileModal: boolean;
  showAttendantDeleteModal: boolean;
  showDeletedMemberships: boolean;
  memberCredentialsSearchTerm: string;
}

export const initialState: ITeamState = {
  members: [],
  driverProfiles: [],
  teamSearchTerm: '',
  driverProfileSearchTerm: '',
  selectedDriverProfile: null,
  showDriverProfileModal: false,
  selectedAttendantProfile: null,
  attendantProfileSearchTerm: '',
  showAttendantProfileModal: false,
  showAttendantDeleteModal: false,
  showDeletedMemberships: false,
  memberCredentialsSearchTerm: '',
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setMembers: (state, action: PayloadAction<Person[]>) => {
      state.members = action.payload;
    },
    setTeamSearchTerm: (state, action: PayloadAction<string>) => {
      state.teamSearchTerm = action.payload;
    },
    setDriverProfileSearchTerm: (state, action: PayloadAction<string>) => {
      state.driverProfileSearchTerm = action.payload;
    },
    setSelectedDriverProfile: (
      state,
      action: PayloadAction<DriverProfile | null>
    ) => {
      state.selectedDriverProfile = action.payload;
    },
    setShowDriverProfileModal: (state, action: PayloadAction<boolean>) => {
      state.showDriverProfileModal = action.payload;
    },
    setAttendantProfileSearchTerm: (state, action: PayloadAction<string>) => {
      state.attendantProfileSearchTerm = action.payload;
    },
    setSelectedAttendantProfile: (
      state,
      action: PayloadAction<AttendantRead | null>
    ) => {
      state.selectedAttendantProfile = action.payload;
    },
    setShowAttendantProfileModal: (state, action: PayloadAction<boolean>) => {
      state.showAttendantProfileModal = action.payload;
    },
    setShowAttendantDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showAttendantDeleteModal = action.payload;
    },
    setShowDeletedMemberships: (state, action: PayloadAction<boolean>) => {
      state.showDeletedMemberships = action.payload;
    },
    setMemberCredentialsSearchTerm: (state, action: PayloadAction<string>) => {
      state.memberCredentialsSearchTerm = action.payload;
    },
  },
});

export const {
  setMembers,
  setTeamSearchTerm,
  setDriverProfileSearchTerm,
  setSelectedDriverProfile,
  setShowDriverProfileModal,
  setAttendantProfileSearchTerm,
  setSelectedAttendantProfile,
  setShowAttendantProfileModal,
  setShowAttendantDeleteModal,
  setShowDeletedMemberships,
  setMemberCredentialsSearchTerm,
} = teamSlice.actions;
