import { useEffect, useMemo, useState } from 'react';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';

import { SortableItem } from './SortableItem';

interface WithId {
  id: string;
}

type VerticalSortableDragAndDropListProps<T extends WithId> = {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onReorder?: (items: T[]) => void;
};

/**
 * Wrapper for https://docs.dndkit.com/ with "sensible" defaults.
 * Mostly just implements https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/presets-sortable-vertical--basic-setup
 * Supporting a horizontal list may simple changing of styles and modifiers, but haven't tried.
 * There are further examples if you need multiple drop containers, zones, etc.
 * May be a need to add handles and/or button for reordering for accessibility.
 */
export function VerticalSortableDragAndDropList<T extends WithId>({
  items,
  renderItem,
  onReorder,
}: VerticalSortableDragAndDropListProps<T>) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [orderedItems, setOrderedItems] = useState<T[]>(items);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const activeItem = useMemo(() => {
    return orderedItems.find((item) => item.id === activeId);
  }, [activeId, orderedItems]);

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  return (
    <DndContext
      modifiers={[
        restrictToVerticalAxis,
        restrictToWindowEdges,
        restrictToFirstScrollableAncestor,
      ]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={function handleDragStart(event) {
        const { active } = event;

        setActiveId(active.id as string);
      }}
      onDragEnd={function handleDragEnd(event) {
        const { active, over } = event;

        if (over?.id && active.id !== over.id) {
          setOrderedItems((oldItems) => {
            const oldIndex = oldItems.findIndex(
              (item) => item.id === active.id
            );
            const newIndex = oldItems.findIndex((item) => item.id === over.id);

            const newValue = arrayMove(oldItems, oldIndex, newIndex);
            onReorder?.(newValue);
            return newValue;
          });
        }

        setActiveId(null);
      }}
    >
      <SortableContext
        items={orderedItems}
        strategy={verticalListSortingStrategy}
      >
        <ol className="flex flex-col gap-y-3">
          {orderedItems.map((item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              isBeingDragged={item.id === activeId}
            >
              <div className="flex items-center gap-4">
                <div className="font-mono text-xl">{index + 1}</div>
                <div className="flex-grow">{renderItem(item)}</div>
              </div>
            </SortableItem>
          ))}
        </ol>
      </SortableContext>
      {createPortal(
        <DragOverlay>
          {activeItem ? (
            <div className="flex cursor-grabbing items-center gap-4">
              <div className="font-mono text-xl">
                {orderedItems.findIndex((item) => item.id === activeItem.id) +
                  1}
              </div>
              <div className="flex-grow shadow-md transition-shadow">
                {renderItem(activeItem)}
              </div>
            </div>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
