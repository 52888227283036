import { useMemo } from 'react';

import {
  MembershipRead,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

interface MembershipColumnFilterProps {
  open: boolean;
  selectedMembers: string[];
  onChange: (value: string[]) => void;
  onClose: () => void;
}
export function MembershipColumnFilter({
  open,
  selectedMembers,
  onChange,
  onClose,
}: MembershipColumnFilterProps) {
  const { data } = useMembersListQuery({});
  const selectedValues: MembershipRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) => selectedMembers.includes(v.id));
  }, [data, selectedMembers]);

  return (
    <MultiSelectFilterPopover
      open={open}
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
      onCancel={onClose}
      onChange={(value) => onChange(value.map((v) => v.id))}
      triggerElement={<div data-testid="membership-column-filter-trigger" />}
    />
  );
}
