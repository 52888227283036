import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

export type PieCell = {
  id: string;
  name: string;
  value: number;
  backgroundColor: string;
};

type DonutChartProps = {
  cells: PieCell[];
};

export function DonutChart({ cells }: DonutChartProps) {
  return (
    <ResponsiveContainer height={250} width="100%">
      <PieChart>
        <Pie
          isAnimationActive={false}
          dataKey="value"
          data={cells}
          innerRadius={60}
          outerRadius={80}
          label
        >
          {cells.map((entry) => (
            <Cell key={`cell-${entry.id}`} fill={entry.backgroundColor} />
          ))}
        </Pie>
        <Legend align="right" verticalAlign="middle" layout="vertical" />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
