import React from 'react';

import { DefaultInputComponentProps } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

import { TextInput } from '../TextInput';

interface PhoneInputProps extends DefaultInputComponentProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  dataTestId?: string;
  disabled?: boolean;
}

export const PhoneInput = React.forwardRef(function _PhoneInput(
  { value, onChange, error, dataTestId, disabled, ...props }: PhoneInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Input
      {...props}
      ref={ref}
      country="US"
      value={value}
      onChange={onChange}
      disabled={disabled}
      // I tried with the types here, but it wants InputComponent<DefaultInputComponentProps> -- InputComponent doesn't exist
      // This is probably something weird I'm doing here causing type inference to go haywire.
      inputComponent={TextInput as any}
      className={`block w-full rounded-md shadow-sm disabled:cursor-not-allowed disabled:text-gray-400 sm:text-sm ${
        error
          ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
          : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
      }`}
      data-testid={dataTestId}
    />
  );
});
