import { useMemo } from 'react';

import {
  useVehiclesListQuery,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

interface VehicleColumnFilterProps {
  open: boolean;
  selectedVehicles: string[];
  onChange: (value: string[]) => void;
  onClose: () => void;
}
export function VehicleColumnFilter({
  open,
  selectedVehicles,
  onChange,
  onClose,
}: VehicleColumnFilterProps) {
  const { data } = useVehiclesListQuery({});
  const selectedValues: VehicleRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) => selectedVehicles.includes(v.id));
  }, [data, selectedVehicles]);

  return (
    <MultiSelectFilterPopover
      open={open}
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={({ nickname }) => nickname}
      onCancel={onClose}
      onChange={(value) => onChange(value.map((v) => v.id))}
      triggerElement={<div data-testid="vehicle-column-filter-trigger" />}
    />
  );
}
