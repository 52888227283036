import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Modal } from 'src/common/primitives/Modal';
import { Wizard } from 'src/common/wizard/Wizard';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';
import { dispatcherSlice } from '../dispatcher.slice';
import { MultiloadOrderStep } from './MultiloadOrderStep';
import { MultiloadSelectionStep } from './MultiloadSelectionStep';

export function MultiloadAssignmentFlow() {
  const dispatch = useAppDispatch();
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );
  const {
    isMultiloadAssignmentFlowOpen,
    selectedTripIdsToMultiload,
    originatingTripId,
    assignmentIdToMultiload,
    multiLoadId,
    mode,
  } = useAppSelector((state) => state.dispatcher.multiloadAssignmentFlow);
  const tripMenuActions = useTripMenuActions();
  return (
    <Modal
      open={isMultiloadAssignmentFlowOpen}
      onClose={() => {
        dispatch(dispatcherSlice.actions.onMultiloadFlowCancel());
      }}
      setOpen={() => {}}
    >
      <Wizard
        steps={[
          {
            title: 'Multi-Load Selection',
            render: ({ next }) => (
              <MultiloadSelectionStep
                onNext={() => {
                  if (selectedTripIdsToMultiload.length > 1) {
                    next();
                  } else {
                    const tripToAssign = unassignedTrips?.find(
                      (trip) => trip.id === originatingTripId
                    );
                    if (tripToAssign) {
                      dispatch(dispatcherSlice.actions.onMultiloadFlowCancel());
                      tripMenuActions.assign(
                        originatingTripId,
                        assignmentIdToMultiload,
                        tripToAssign.status
                      );
                      return;
                    } else if (mode === 'edit-trip-multi-load' && multiLoadId) {
                      tripMenuActions.removeMultiLoad(multiLoadId);
                      dispatch(dispatcherSlice.actions.onMultiloadFlowCancel());
                    }
                  }
                }}
              />
            ),
          },
          {
            title: 'Multi-Load Order',
            render: () => <MultiloadOrderStep />,
          },
        ]}
      />
    </Modal>
  );
}
