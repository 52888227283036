import { SpaceTypeEnum } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export const transportTypeOptions: SelectOption[] = [
  {
    label: 'Wheelchair',
    value: 'wheelchair',
  },
  {
    label: 'Wheelchair XL',
    value: 'wheelchair xl',
  },
  {
    label: 'Stretcher',
    value: 'stretcher',
  },
  {
    label: 'Ambulatory',
    value: 'ambulatory',
  },
  {
    label: 'Broda Chair',
    value: 'broda chair',
  },
  {
    label: 'Geri Chair',
    value: 'geri chair',
  },
];

export const transportTypeOptionsMap: Record<SpaceTypeEnum, string> =
  transportTypeOptions.reduce((acc, option) => {
    return {
      ...acc,
      [option.value]: option.label,
    };
  }, {} as Record<SpaceTypeEnum, string>);
