import { Controller, useFormContext } from 'react-hook-form';

import { BillingSettingsRead } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';
import { PhoneInput } from 'src/common/primitives/PhoneInput';
import { SettingsRowHeader } from 'src/features/settings/OrganizationSettingsForm/components/SettingsRowHeader';

interface BillingSettingsFormProps {
  minimumInvoiceNumber: number;
}
export default function BillingSettingsForm({
  minimumInvoiceNumber,
}: BillingSettingsFormProps) {
  const { control } = useFormContext<BillingSettingsRead>(); // retrieve all hook methods

  return (
    <div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 pb-5 md:grid-cols-4">
        <SettingsRowHeader
          title="Invoice"
          description="Auto-generating invoice number settings"
        />
        <div className="grid grid-cols-2 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
          <Controller
            control={control}
            name="invoice_prefix"
            render={({ field }) => {
              return (
                <FormFieldDefault
                  inputProps={{
                    ...field,
                    value: field.value ?? '',
                    id: 'invoice_prefix',
                  }}
                  label="Invoice Prefix"
                  type="text"
                />
              );
            }}
          />
          <Controller
            control={control}
            name="invoice_seed_number"
            rules={{
              min: {
                value: minimumInvoiceNumber,
                message: 'Seed number cannot be lower than its current value',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormFieldDefault
                  inputProps={{
                    ...field,
                    value: field.value ?? '',
                    id: 'invoice_seed_number',
                  }}
                  error={fieldState.error?.message ?? ''}
                  label="Seed Number"
                  type="number"
                />
              );
            }}
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 pb-5 md:grid-cols-4">
        <SettingsRowHeader
          title="&nbsp;"
          description="Billing contact information, appears on the invoice"
        />
        <div className="grid grid-cols-2 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
          <Controller
            control={control}
            name="email"
            render={({ field }) => {
              return (
                <FormFieldDefault
                  inputProps={{
                    ...field,
                    value: field.value ?? '',
                    id: 'email',
                  }}
                  label="Billing Email"
                  type="email"
                />
              );
            }}
          />
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => {
              return (
                <DefaultFormFieldLayout
                  label="Billing Phone"
                  inputProps={{ id: 'phone_number' }}
                >
                  <PhoneInput {...field} placeholder="(555) 555-5555" />
                </DefaultFormFieldLayout>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
