import Datadog from 'react-datadog';
import { Provider as ReduxProvider } from 'react-redux';

import store from 'src/app/store';
import { configureAuth } from 'src/common/external/aws';
import { PaymentMethodCaptureProvider } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureContext';
import { RequiresConfirmationModal } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { ToastConfigProvider } from 'src/common/primitives/Toast';
import { TooltipProvider } from 'src/common/primitives/Tooltip';
import { ThemeProvider } from 'src/common/theme/ThemeProvider';
import { RequireAuth } from 'src/features/auth/requireAuth';

import { Routing } from './app.routes';
import { DelinquentBanner } from './banner/DelinquentBanner';

configureAuth();

function App() {
  return (
    <Datadog
      applicationId={process.env.REACT_APP_DATADOG_APP_ID || ''}
      clientToken={process.env.REACT_APP_DATADOG_CLIENT_TOKEN || ''}
      sessionReplayRecording
      service="bambi-web"
      env={process.env.NODE_ENV}
      // TODO:
      // Specify a version number to identify the deployed version of your application in Datadog
      // version='1.0.0',
    >
      <div className="App">
        <ReduxProvider store={store}>
          <ThemeProvider>
            <ToastConfigProvider>
              <TooltipProvider delayDuration={200}>
                <RequireAuth>
                  <PaymentMethodCaptureProvider>
                    <DelinquentBanner />
                    <Routing />
                    <RequiresConfirmationModal />
                  </PaymentMethodCaptureProvider>
                </RequireAuth>
              </TooltipProvider>
            </ToastConfigProvider>
          </ThemeProvider>
        </ReduxProvider>
      </div>
    </Datadog>
  );
}

export default App;
