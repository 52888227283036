import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import {
  onClearFilters,
  setCredentialSearchTerm,
  setShowCredentialModal,
} from './credential.slice';

export function CredentialRouteActions() {
  const dispatch = useAppDispatch();
  const {
    credentialSearchTerm,
    selectedCredentialTypes,
    selectedMembers,
    selectedVehicles,
  } = useAppSelector((state) => state.credential);

  const showClearFiltersButton = !!(
    selectedCredentialTypes.length ||
    selectedMembers.length ||
    selectedVehicles.length
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setCredentialSearchTerm,
    credentialSearchTerm
  );

  return (
    <>
      {showClearFiltersButton && (
        <Button
          onClick={() => {
            dispatch(onClearFilters());
          }}
          variant="anchor"
          dataTestId="clear-credential-filters-button"
        >
          clear filters
        </Button>
      )}
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search credentials"
        value={inputValue}
      />

      <Button
        onClick={() => {
          dispatch(setShowCredentialModal(true));
        }}
        variant="primary"
        dataTestId="add-credential-button"
      >
        Add Credential
      </Button>
    </>
  );
}
