import { TabNavigation } from 'src/common/TabNavigation';

const tabs = [
  { name: 'Overview', href: '/reports' },
  { name: 'Drivers', href: '/reports/drivers' },
  { name: 'Payers', href: '/reports/payers' },
];

export function ReportsNav() {
  return <TabNavigation tabs={tabs} />;
}
