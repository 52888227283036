import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';

import { dispatcherSlice } from '../../dispatcher.slice';

export function AssignmentColumnExpandButton({
  assignmentId,
}: {
  assignmentId: string;
}) {
  const dispatch = useAppDispatch();
  const assignmentColumnVisualState = useAppSelector(
    (state) => state.dispatcher.assignmentColumnVisualState
  );
  const isExpanded = useMemo(
    () => assignmentColumnVisualState[assignmentId]?.isExpanded === true,
    [assignmentColumnVisualState, assignmentId]
  );
  return (
    <DropdownMenuItem
      data-testid={`assignment-column-expand-button-${assignmentId}`}
      onClick={() => {
        toggleExpansionInDOM(isExpanded, assignmentId);
        dispatch(
          dispatcherSlice.actions.toggleAssignmentColumnExpanded(assignmentId)
        );
      }}
    >
      {`${isExpanded ? 'Shrink' : 'Expand'} Column`}
    </DropdownMenuItem>
  );
}

const MOBISCROLL_RESOURCE_COLUMN_SELECTOR = '.mbsc-schedule-resource';
const MOBISCROLL_SCHEDULE_COLUMN_SELECTOR = '.mbsc-schedule-column';
const defaultColumnStyleAttribute = 'transition: min-width 0.3s ease-in-out';

function toggleExpansionInDOM(isExpanded: boolean, assignmentId: string) {
  const allAssignmentHeaders = document.querySelectorAll(
    MOBISCROLL_RESOURCE_COLUMN_SELECTOR
  );
  const indexOfCurrentAssignmentHeader = Array.from(
    allAssignmentHeaders
  ).findIndex((header) => {
    return header.querySelector(`#assignment-header-${assignmentId}`);
  });
  const allAssignmentScheduleColumns = document.querySelectorAll(
    MOBISCROLL_SCHEDULE_COLUMN_SELECTOR
  );
  if (!isExpanded) {
    const currentWidth =
      allAssignmentHeaders[
        indexOfCurrentAssignmentHeader
      ].getBoundingClientRect().width;
    const newColumnStyleValue = `min-width: calc(${currentWidth}px + max(15vw, 150px)); transition: min-width 0.3s ease-in-out`;
    allAssignmentHeaders[indexOfCurrentAssignmentHeader].setAttribute(
      'style',
      newColumnStyleValue
    );
    allAssignmentScheduleColumns[indexOfCurrentAssignmentHeader].setAttribute(
      'style',
      newColumnStyleValue
    );
  } else {
    allAssignmentHeaders[indexOfCurrentAssignmentHeader].setAttribute(
      'style',
      defaultColumnStyleAttribute
    );
    allAssignmentScheduleColumns[indexOfCurrentAssignmentHeader].setAttribute(
      'style',
      defaultColumnStyleAttribute
    );
  }
}
