import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import {
  CredentialTypeRead,
  useCredentialsTypesListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

interface CredentialTypeColumnFilterProps {
  open: boolean;
  selectedCredentialTypes: string[];
  onChange: (value: string[]) => void;
  onClose: () => void;
}
export function CredentialTypeColumnFilter({
  open,
  selectedCredentialTypes,
  onChange,
  onClose,
}: CredentialTypeColumnFilterProps) {
  const location = useLocation();

  const category =
    location.pathname === '/fleet/credentials' ? 'Vehicle' : 'Team';

  const { data } = useCredentialsTypesListQuery({
    category,
  });

  const selectedValues: CredentialTypeRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) => selectedCredentialTypes.includes(v.id));
  }, [data, selectedCredentialTypes]);

  return (
    <MultiSelectFilterPopover
      open={open}
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={({ name }) => name}
      onCancel={onClose}
      onChange={(value) => onChange(value.map((v) => v.id))}
      triggerElement={<div data-testid="credential-type-filter-trigger" />}
    />
  );
}
