import { useCallback, useEffect, useRef } from 'react';

import { endsWith } from 'lodash-es';
import { useReactToPrint } from 'react-to-print';

import { useAuth } from 'src/features/auth/useAuth';

export type CondensedReportRecord = {
  trip_date: string;
  external_trip_id: string;
  space_type: string;
  passenger_full_name: string;
  scheduled_pickup_at: string;
  actual_at_pickup_at: string;
  actual_at_dropoff_at: string;
  pickup_address: string;
  dropoff_address: string;
  total_distance_miles: string;
  status: string;
  pickup_passenger_signature_url: string;
  dropoff_passenger_signature_url: string;
  driver_name: string;
  driver_license_state: string;
  driver_license_number: string;
  vin: string;
};

const columnNameToLabelMap: Record<keyof CondensedReportRecord, string> = {
  trip_date: 'Trip Date',
  external_trip_id: 'Trip ID',
  space_type: 'LOS',
  status: 'Status',
  passenger_full_name: "Rider's Name",
  pickup_address: 'PU Address',
  dropoff_address: 'DO Address',
  scheduled_pickup_at: 'Scheduled Time',
  actual_at_pickup_at: 'PU Actual Time',
  actual_at_dropoff_at: 'DO Actual Time',
  total_distance_miles: 'Miles',
  driver_name: 'Driver',
  driver_license_state: 'Driver State',
  driver_license_number: 'License',
  vin: 'VIN',
  pickup_passenger_signature_url: 'Pickup Signature Image',
  dropoff_passenger_signature_url: 'Dropoff Signature Image',
};

const columnOrder: Array<keyof CondensedReportRecord> = [
  'trip_date',
  'external_trip_id',
  'space_type',
  'passenger_full_name',
  'scheduled_pickup_at',
  'actual_at_pickup_at',
  'actual_at_dropoff_at',
  'pickup_address',
  'dropoff_address',
  'total_distance_miles',
  'status',
  'pickup_passenger_signature_url',
  'dropoff_passenger_signature_url',
  'driver_name',
  'driver_license_state',
  'driver_license_number',
  'vin',
];

const columnSizes: Partial<Record<keyof CondensedReportRecord, string>> = {
  trip_date: '50px',
  pickup_passenger_signature_url: '40px',
  dropoff_passenger_signature_url: '40px',
};

interface PrintableCondensedReportProps {
  data: Array<CondensedReportRecord>;
  documentTitle: string;
  printOnMount: boolean;
  onPrint?: () => void;
}

export function PrintableCondensedReport({
  data,
  documentTitle,
  printOnMount,
  onPrint,
}: PrintableCondensedReportProps) {
  const { currentOrganizationName, currentOrganizationSettings } = useAuth();
  const printableReportRef = useRef<HTMLDivElement>(null);
  const triggerPrint = useReactToPrint({
    content: () => printableReportRef.current,
    documentTitle,
    onAfterPrint: onPrint,
  });

  useEffect(() => {
    if (printOnMount) {
      triggerPrint();
    }
  }, [printOnMount, triggerPrint]);

  const transformValue = useCallback(
    (column: keyof CondensedReportRecord, data: string) => {
      if (endsWith(column, 'signature_url') && data) {
        return <img alt={columnNameToLabelMap[column]} src={data} />;
      }

      if (column === 'total_distance_miles' && data) {
        return parseFloat(data).toFixed(2);
      }

      return data;
    },
    []
  );

  return (
    <div
      className="bg-white p-2 screen:hidden"
      ref={printableReportRef}
      style={{ fontSize: '8px' }}
    >
      <table className="table-fixed border">
        <thead className="bg-mint-light">
          <tr>
            <th colSpan={columnOrder.length}>
              {currentOrganizationSettings?.logo ? (
                <img
                  alt={`Company logo for ${currentOrganizationName}`}
                  src={currentOrganizationSettings.logo}
                  width="100px"
                  height="50px"
                />
              ) : null}
            </th>
          </tr>
          <tr>
            <th
              colSpan={columnOrder.length}
              className="border text-left align-bottom"
            >
              {currentOrganizationName}
            </th>
          </tr>
          <tr>
            <th
              colSpan={columnOrder.length}
              className="border text-left align-bottom italic"
            >
              We authorize the information below to be verified and we certify
              the information provided on this form is true, correct and
              accurate.
            </th>
          </tr>

          <tr>
            {columnOrder.map((c) => (
              <th
                className="border align-bottom"
                style={{
                  width: columnSizes[c],
                }}
                key={c}
              >
                {columnNameToLabelMap[c]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr
              className={i % 2 === 0 ? 'bg-slate-100' : ''}
              key={row.external_trip_id}
            >
              {columnOrder.map((c) => (
                <td
                  className="border align-bottom"
                  key={`${row.external_trip_id}-${i}-${c}`}
                >
                  {transformValue(c, row[c])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
