import { useCallback, useState } from 'react';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useTripsBulkUnassignCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'src/common/modals/ConfirmModal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import {
  dismissDispatchModal,
  useDispatchModalToShow,
} from '../dispatcher.slice';
import { useBulkUnMultiLoadOnBulkUnassign } from './useBulkUnMultiLoadOnBulkUnassign';

export const UnassignConfirmationModal = () => {
  const props = useUnassignConfirmationModalProps();
  return <ConfirmModal {...props} />;
};

// Extracted hook for testability since modals don't render in React root
export const useUnassignConfirmationModalProps = (): ConfirmModalProps => {
  const modalToShow = useDispatchModalToShow();
  const { onConfirmUnassign, dismissUnassignConfirmation, unassignError } =
    useUnassignConfirmation();
  return {
    open: modalToShow === 'unassign',
    setOpen: (isOpen) => {
      if (!isOpen) dismissUnassignConfirmation();
    },
    onCancel: dismissUnassignConfirmation,
    onConfirm: onConfirmUnassign,
    cancelText: 'Cancel',
    confirmText: 'Unassign',
    title: 'Unassign All Driver Trips',
    description:
      'Are you sure you want to unassign trips? Locked and in progress trips will not be unassigned.',
    error: unassignError,
    icon: <ExclamationTriangleIcon className="w-5" />,
    mode: 'destructive',
  };
};

export const useUnassignConfirmation = () => {
  const multiLoadFeatureResponse = useFeature('multi-loading');
  const dispatch = useAppDispatch();
  const assignmentId = useAppSelector(
    (state) => state.dispatcher.modals.assignmentId
  );
  const removeMultiLoadsForTripsToUnassign = useBulkUnMultiLoadOnBulkUnassign();
  const nextToastId = useAppSelector((state) => state.toast.nextId);
  const [unassignError, setUnassignError] = useState<string | undefined>(
    undefined
  );

  const dismissUnassignConfirmation = useCallback(() => {
    dispatch(dismissDispatchModal());
    setUnassignError(undefined);
  }, [dispatch]);

  const [unassignBulk] = useTripsBulkUnassignCreateMutation();

  const handleUnassignAllTrips = useCallback(async () => {
    try {
      if (!assignmentId) {
        throw new Error('Missing assignment ID');
      }

      await unassignBulk({
        bulkTripUnassign: { assignment_id: assignmentId },
      }).unwrap();

      if (multiLoadFeatureResponse.isEnabled) {
        await removeMultiLoadsForTripsToUnassign(assignmentId);
      }

      dispatch(
        show({
          id: nextToastId,
          title: 'Trips Unassigned',
          description: 'All trips have been unassigned',
          type: 'success',
        })
      );

      dismissUnassignConfirmation();
    } catch (error) {
      const formattedError = formatServerError(error);
      setUnassignError(formattedError);
    }
  }, [
    assignmentId,
    multiLoadFeatureResponse.isEnabled,
    unassignBulk,
    dispatch,
    nextToastId,
    dismissUnassignConfirmation,
    removeMultiLoadsForTripsToUnassign,
  ]);

  return {
    unassignError,
    dismissUnassignConfirmation,
    onConfirmUnassign: () => handleUnassignAllTrips(),
  };
};
