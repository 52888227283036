import { useMemo } from 'react';

import { usePayerinfooneschematemplateListQuery } from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { SelectOption } from 'src/common/primitives/Select';

interface GlobalPayerSelectProps {
  error?: string;
  value?: SelectOption<string>;
  onChange: (value: SelectOption) => void;
}
export function GlobalPayerSelect({
  error,
  value,
  onChange,
}: GlobalPayerSelectProps) {
  const { data, isLoading } = usePayerinfooneschematemplateListQuery({});

  const payerTemplateOptions = useMemo(() => {
    const options = [
      {
        value: '',
        label: 'No Mapping',
      },
      ...(data?.results.map((info) => {
        return {
          label: info.one_schema_template.template_name,
          value: info.payer_info.id,
        };
      }) ?? []),
    ];

    return options;
  }, [data]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <FormField
      label="Upload Template Mapping (Bambi Staff Only)"
      error={error}
      type="select"
      inputProps={{
        id: 'global-payer',
      }}
      value={value}
      options={payerTemplateOptions}
      onChange={onChange}
    />
  );
}
