import { useAppDispatch } from 'src/app/store';
import { useLazyAssignmentsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { assignmentManagementSlice } from 'src/features/team/assignmentManagement.slice';

import { MenuItem } from './AssignmentHeaderMenu';

export function EditAssignmentMenuItem({
  assignmentId,
}: {
  assignmentId: string;
}) {
  const dispatch = useAppDispatch();
  const [fetchAssignement] = useLazyAssignmentsRetrieveQuery();
  return (
    <MenuItem
      label="Edit assignment"
      onClick={async () => {
        const assignment = await fetchAssignement({
          id: assignmentId,
        }).unwrap();
        dispatch(
          assignmentManagementSlice.actions.setSelectedAssignment(assignment)
        );
        dispatch(
          assignmentManagementSlice.actions.setShowAssignmentManagementModal(
            true
          )
        );
      }}
    />
  );
}
