import { ShieldCheckIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { setShowCredentialModal } from '../credential.slice';
import { CredentialCategory } from '../types';

interface CredentialsEmptyStateProps {
  category: CredentialCategory;
}

export default function CredentialsEmptyState({
  category,
}: CredentialsEmptyStateProps) {
  const dispatch = useAppDispatch();
  const handleAddCredential = () => {
    dispatch(setShowCredentialModal(true));
  };

  const categoryDisplay = category === 'Vehicle' ? 'vehicle' : 'member';

  return (
    <div
      className="flex justify-center px-[24px] py-12"
      data-testid="credentials-empty-state"
    >
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <ShieldCheckIcon className="mx-auto h-6 w-auto text-gray-700" />
        </div>
        <h4 className="mt-3 text-gray-700">No Credentials Have Been Added</h4>
        <p className="text-gray-400">
          Add a credential to an existing {categoryDisplay}
        </p>
        <div className="mt-3 text-gray-700">
          <Button onClick={() => handleAddCredential()} variant="primary">
            Add Credential
          </Button>
        </div>
      </div>
    </div>
  );
}
