import { SpaceTypeEnum } from 'src/common/external/bambi-api/bambiApi';

import { SpaceTypeIcons } from './SpaceTypeIcons';

type VehicleHeaderProps = {
  type: SpaceTypeEnum;
  name: string;
  showCondensed: boolean;
};

export function VehicleHeader({
  type,
  name,
  showCondensed: isWidthSmall,
}: VehicleHeaderProps) {
  const TypeIcon = SpaceTypeIcons[type];
  return (
    <div
      className={`border-t-4 border-space-type-${type}-primary pt-2 pl-1 ${
        isWidthSmall && 'text-xs'
      }`}
      title={name}
    >
      <div className="flex items-center gap-x-1 truncate whitespace-nowrap">
        <TypeIcon /> {name}
      </div>
    </div>
  );
}
