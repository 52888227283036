import { useMemo } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

import { RootState, useAppSelector } from 'src/app/store';
import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DropdownSubmenu } from 'src/common/DropdownMenu/DropdownSubmenu';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';
import { isTripStatusRevertable } from 'src/features/add-trip/useAddATripOnSubmit/isTripStatusRevertable';
import { DriverAssignmentMenu } from 'src/features/dispatch/menus/DriverAssignmentMenu/DriverAssignmentMenu';
import { TripDropdownSharedMenu } from 'src/features/dispatch/menus/TripDropdownSharedMenu';

import { ChangeTripTime } from '../../ChangeTripTime';
import { useTripMenuActions } from '../../useTripMenuActions';
import { AddPickupWaitTime } from './AddPickupWaitTime';
import { ChangeStatusMenu } from './ChangeStatusMenu';
import { getLockActionConfig } from './getLockActionConfig';

interface EventMenuProps {
  tripId: string;
}

export function EventMenu({ tripId }: EventMenuProps) {
  const menuActions = useTripMenuActions();
  const trip = useTrip(tripId);

  const showTripMenu = useMemo(() => {
    if (!trip) return false;

    const endOfDayDateTime = DateTime.local().endOf('day');
    const pickupDateTime = DateTime.fromISO(trip.scheduled_pickup_at);

    const isPickupInFuture = pickupDateTime > endOfDayDateTime;

    return trip.status !== 'canceled' && !isPickupInFuture;
  }, [trip]);

  if (!trip) return <div>Trip not found for {tripId}</div>;
  const lockActionConfig = getLockActionConfig(
    !!trip.is_locked,
    menuActions.lock,
    menuActions.unlock
  );

  return (
    <DropdownMenuBasic
      trigger={
        <EllipsisVerticalIcon
          className="h-4 w-4"
          data-testid="event-menu-trigger"
        />
      }
    >
      <TripDropdownSharedMenu trip={trip}>
        <DropdownSubmenu triggerText="Add Pickup Wait Time">
          <AddPickupWaitTime
            tripId={trip.id}
            existingWaitTimeSeconds={trip.pickup_wait_time_seconds}
          />
        </DropdownSubmenu>
        <DropdownSubmenu triggerText="Change Pickup Time">
          <ChangeTripTime tripId={trip.id} />
        </DropdownSubmenu>
        {'assignment_id' in trip ? (
          <DropdownMenuItem onClick={() => lockActionConfig.onClick(trip.id)}>
            {lockActionConfig.title}
          </DropdownMenuItem>
        ) : null}
        {'assignment_id' in trip && (
          <DropdownMenuItem
            onClick={() => menuActions.unassign(trip.id, trip.multiload_id)}
          >
            Unassign
          </DropdownMenuItem>
        )}
        <DriverAssignmentMenu trip={trip} />
        {isTripStatusRevertable(trip.status) && (
          <DropdownMenuItem onClick={() => menuActions.revertStatus(trip.id)}>
            Revert status
          </DropdownMenuItem>
        )}
        {showTripMenu && <ChangeStatusMenu trip={trip} />}
      </TripDropdownSharedMenu>
    </DropdownMenuBasic>
  );
}

function useTrip(tripId: string): DispatchTripRead | undefined {
  const trip = useAppSelector((state: RootState) => {
    const trips = [
      state.dispatcher.dispatchResponse?.assigned_trips,
      state.dispatcher.dispatchResponse?.unassigned_trips,
    ]
      .flat()
      .filter(Boolean);
    return trips.find((trip) => trip?.id === tripId);
  });

  return trip;
}
