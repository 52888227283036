import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { DefaultErrorElement } from 'src/app/DefaultErrorElement';
import { NotFoundRoute } from 'src/app/NotFoundRoute';
import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { BillingLayout } from 'src/features/billing/BillingLayout';
import { invoiceRoutes } from 'src/features/billing/routes';
import { ChatLayout } from 'src/features/chat/ChatLayout';
import { chatRoutes } from 'src/features/chat/routes';
import { DefaultRouteLayout } from 'src/features/dispatch';
import { dispatchRoutes } from 'src/features/dispatch/dispatcher.routes';
import { fleetRoutes } from 'src/features/fleet';
import { FleetLayout } from 'src/features/fleet/FleetLayout';
import { liveMapRoutes } from 'src/features/live-map';
import { passengerRoutes } from 'src/features/passenger';
import { PassengerLayout } from 'src/features/passenger/PassengerLayout';
import { PayerLayout } from 'src/features/payer/PayerLayout';
import { payerRoutes } from 'src/features/payer/routes';
import { PricingLayout } from 'src/features/pricing/PricingLayout';
import { pricingRoutes } from 'src/features/pricing/routes';
import { PricingModelLayout } from 'src/features/pricingModel/PricingModelLayout';
import { pricingModelRoutes } from 'src/features/pricingModel/routes';
import { ReportsLayout } from 'src/features/reports/ReportsLayout';
import { reportsRoutes } from 'src/features/reports/routes';
import { settingsRoutes } from 'src/features/settings/routes';
import { SettingsLayout } from 'src/features/settings/SettingsLayout';
import { rideOrderingPortalTeamRoutes, teamRoutes } from 'src/features/team';
import { TeamLayout } from 'src/features/team/TeamLayout';
import {
  rideOrderingPortalTripRoutes,
  tripRoutes,
} from 'src/features/trip/routes';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/dispatch" />,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/dispatch',
    element: <DefaultRouteLayout />,
    children: dispatchRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/live-map',
    element: <DefaultRouteLayout />,
    children: liveMapRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/team/*',
    element: <TeamLayout />,
    children: teamRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/fleet/*',
    element: <FleetLayout />,
    children: fleetRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/passengers/*',
    element: <PassengerLayout />,
    children: passengerRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/trips/*',
    element: <DefaultRouteLayout />,
    children: tripRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/payers/*',
    element: <PayerLayout />,
    children: payerRoutes,
    errorElement: <DefaultErrorElement />,
  },
  // TODO: This is v0 and will be deprecated once we move everyone to the new pricing
  {
    path: '/pricing/pricing-models/*',
    element: <PricingModelLayout />,
    children: pricingModelRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/pricing/',
    element: <PricingLayout />,
    children: pricingRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/settings/*',
    element: <SettingsLayout />,
    children: settingsRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/chat/*',
    element: <ChatLayout />,
    children: chatRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/billing/*',
    element: <BillingLayout />,
    children: invoiceRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/reports/*',
    element: <ReportsLayout />,
    children: reportsRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '*',
    element: <NotFoundRoute />,
    errorElement: <DefaultErrorElement />,
  },
];

export const rideOrderingPortalRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/trips" />,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/live-map',
    element: <DefaultRouteLayout />,
    children: liveMapRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/team/*',
    element: <TeamLayout />,
    children: rideOrderingPortalTeamRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/passengers/*',
    element: <PassengerLayout />,
    children: passengerRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/trips/*',
    element: <DefaultRouteLayout />,
    children: rideOrderingPortalTripRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '*',
    element: <NotFoundRoute />,
    errorElement: <DefaultErrorElement />,
  },
];

export const restrictedSupportRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/trips" />,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/live-map',
    element: <DefaultRouteLayout />,
    children: liveMapRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/team/*',
    element: <TeamLayout />,
    children: teamRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/fleet/*',
    element: <FleetLayout />,
    children: fleetRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/passengers/*',
    element: <PassengerLayout />,
    children: passengerRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/trips/*',
    element: <DefaultRouteLayout />,
    children: tripRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/payers/*',
    element: <PayerLayout />,
    children: payerRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/settings/*',
    element: <SettingsLayout />,
    children: settingsRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '/chat/*',
    element: <ChatLayout />,
    children: chatRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '*',
    element: <NotFoundRoute />,
    errorElement: <DefaultErrorElement />,
  },
];

const routesToRolesMap: Record<RoleEnum, RouteObject[]> = {
  owner: routes,
  admin: routes,
  dispatcher: routes,
  associate: routes,
  'facility admin': rideOrderingPortalRoutes,
  'facility user': rideOrderingPortalRoutes,
  bambi: routes,
  'restricted support': restrictedSupportRoutes,
  // integration "users" should only interact via the API, not the UI
  integration: [],
};
export function Routing() {
  const { role } = useUserRoles();

  // role should always be defined at this point
  // if it's not, we currently mount all routes
  // What is shown to user is handled in the NavBar component
  // TODO: There's probably a more robust/standard way of handling route access
  // using react-router
  // Will need to circle back after getting restricted support role out
  const routesForUser = role ? routesToRolesMap[role] : routes;

  const router = createBrowserRouter(routesForUser);
  return <RouterProvider router={router} />;
}
