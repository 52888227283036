import { CredentialTypeRead } from 'src/common/external/bambi-api/bambiApi';

export const assignDefaultFormValues = (
  credentialType?: CredentialTypeRead
) => {
  return {
    id: credentialType?.id || '',
    name: credentialType?.name || '',
    description: credentialType?.description,
  };
};
