import { useOrganizationBillingStatusRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

export function DelinquentBanner() {
  const { isLoading, data } = useOrganizationBillingStatusRetrieveQuery();

  if (isLoading || !data?.is_delinquent) {
    return null;
  }

  return (
    <div
      data-testid="delinquent_banner"
      className="bg-red-600 p-2 text-center text-base font-bold text-white"
    >
      Urgent: Payment is overdue. Immediate action is required to avoid service
      suspension.
    </div>
  );
}
