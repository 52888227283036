import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { CredentialRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

import {
  setSelectedCredential,
  setSelectedCredentialTypeOption,
  setShowCredentialModal,
} from '../credential.slice';
export function EditCredentialButton({
  credential,
}: {
  credential: CredentialRead;
}) {
  const dispatch = useAppDispatch();

  const onEditClick = () => {
    dispatch(setSelectedCredentialTypeOption(undefined));
    dispatch(setSelectedCredential(credential));
    dispatch(setShowCredentialModal(true));
  };

  return (
    <>
      <Button
        dataTestId="credential-edit-button"
        onClick={onEditClick}
        variant="ghost"
      >
        <PencilIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
      </Button>
    </>
  );
}
