import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import store from 'src/app/store';
import {
  PricingSchemePayerRead,
  PricingSchemeRead,
} from 'src/common/external/bambi-api/bambiApi';
import { formatPriceCell } from 'src/common/formatPriceCell';
import { PayersColumnFilter } from 'src/features/trip/management/filters/PayerColumnFilter';

import { setSelectedPayers } from '../pricing.slice';
import { ClonePricingSchemeButton } from './ClonePricingSchemeButton';
import { PricingSchemeDeleteButton } from './PricingSchemeDeleteButton';
import { RowActions } from './RowActions';

const columnHelper = createColumnHelper<PricingSchemeRead>();

const nameColumnDef = columnHelper.display({
  enableSorting: true,
  id: 'name',
  header: 'Name',
  cell: (props) => props.row.original.name,
});

const descriptionColumnDef = columnHelper.display({
  header: 'Description',
  cell: (props) => props.row.original.description,
});

const payersColumnDef = columnHelper.accessor('payers', {
  header: 'Payers',
  cell: (props) => {
    const { payers } = props.row.original;
    const maxVisiblePayers = 5;

    const formatPayersList = (payers: PricingSchemePayerRead[]) =>
      payers.map((payer) => payer.display_name).join(', ');

    const renderPayers = (payers: PricingSchemePayerRead[]) => {
      if (payers.length > maxVisiblePayers) {
        const visiblePayers = payers.slice(0, maxVisiblePayers);
        return `${formatPayersList(visiblePayers)}, +${
          payers.length - maxVisiblePayers
        } more`;
      }

      return formatPayersList(payers);
    };

    return renderPayers(payers);
  },
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <PayersColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().pricing.selectedPayers}
          setSelectedAction={setSelectedPayers}
        />
      );
    },
    filterCount() {
      const state = store.getState().pricing;
      return state.selectedPayers.length;
    },
  },
});

const startDateColumnDef = columnHelper.display({
  enableSorting: true,
  header: 'Start Date',
  id: 'start_date',
  cell: (props) =>
    DateTime.fromISO(props.row.original.start_date).toLocaleString(),
});

const endDateColumnDef = columnHelper.display({
  enableSorting: true,
  header: 'End Date',
  id: 'end_date',
  cell: (props) =>
    DateTime.fromISO(props.row.original.end_date).toLocaleString(),
});

const baseRateColumnDef = columnHelper.accessor('base_fee_cents', {
  enableSorting: true,
  id: 'base_fee_cents',
  header: 'Base Fee',
  cell: (props) => {
    const { base_fee_cents } = props.row.original;
    return formatPriceCell(base_fee_cents);
  },
});

const loadedMileageRateColumnDef = columnHelper.accessor(
  'loaded_mileage_rate_cents',
  {
    enableSorting: true,
    id: 'loaded_mileage_rate_cents',
    header: 'Loaded Mileage Rate',
    cell: (props) => {
      const { loaded_mileage_rate_cents } = props.row.original;
      return formatPriceCell(loaded_mileage_rate_cents);
    },
  }
);

const editColumnDef = columnHelper.display({
  header: 'Edit',
  cell: ({ row }) => <RowActions pricingSchemeId={row.original.id} />,
  meta: {
    enableHeaderMenu: false,
  },
});

const cloneColumnDef = columnHelper.display({
  header: 'Copy',
  cell: ({ row }) => (
    <ClonePricingSchemeButton pricingSchemeId={row.original.id} />
  ),
  meta: {
    enableHeaderMenu: false,
  },
});

const deleteColumnDef = columnHelper.display({
  header: 'Delete',
  cell: ({ row }) => (
    <PricingSchemeDeleteButton pricingSchemeId={row.original.id} />
  ),
  meta: {
    enableHeaderMenu: false,
  },
});

export const columns: ColumnDef<any, any>[] = [
  nameColumnDef,
  descriptionColumnDef,
  payersColumnDef,
  startDateColumnDef,
  endDateColumnDef,
  baseRateColumnDef,
  loadedMileageRateColumnDef,
  cloneColumnDef,
  editColumnDef,
  deleteColumnDef,
];
