import { isNumber } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';

import { selectCurrentTripSummaryId } from './selectors/selectCurrentTripSummaryId';
import { TripSignatureMarker } from './TripSignatureMarker';
import { tripSummarySlice } from './tripSummary.slice';

export function TripSignatureMarkers() {
  const dispatch = useAppDispatch();
  const tripSignatures = useAppSelector(
    (state) => state.tripSummary.tripSignatures
  );
  const selectedTripEventId = useAppSelector(selectCurrentTripSummaryId);
  const isMapStyleLoaded = useAppSelector(
    (state) => state.tripSummary.isMapStyleLoaded
  );

  return (
    <>
      {tripSignatures
        .filter((signature) => {
          return (
            // Forcing a rerender of the markers when the style is loaded
            isMapStyleLoaded &&
            isNumber(signature.latitude) &&
            isNumber(signature.longitude)
          );
        })
        .map((signature) => (
          <TripSignatureMarker
            key={signature.id}
            tripSignature={signature}
            onClick={() => {
              if (selectedTripEventId === `signature-${signature.id}`) {
                dispatch(tripSummarySlice.actions.deselectTripSummary());
                return;
              }
              dispatch(
                tripSummarySlice.actions.selectTripSummary(
                  `signature-${signature.id}`
                )
              );
            }}
          />
        ))}
    </>
  );
}
