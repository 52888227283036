import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';

import { ReportsNav } from '../ReportsNav';
import ReportsContent from './ReportsContent';

export function ReportsOverviewRoute() {
  return (
    <ToolbarAndContentLayout
      preContent={<ReportsNav />}
      content={<ReportsContent />}
    />
  );
}
