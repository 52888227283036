export default function ProgressBarIndicator({
  progressPercentage,
}: {
  progressPercentage: number;
}) {
  return (
    <div className="h-1 w-full rounded bg-gray-200">
      <div
        className={`h-full rounded bg-mint`}
        data-testid="progress-bar-step-indicator"
        style={{
          transition: 'width 0.3s ease-in-out',
          width: `${progressPercentage}%`,
        }}
      />
    </div>
  );
}
