import { DispatchAssignmentStatusEnum } from 'src/common/external/bambi-api/bambiApi';

type AssignmentStatusBadgeProp = {
  status: DispatchAssignmentStatusEnum;
};

const assignmentStatusClassNames: Record<DispatchAssignmentStatusEnum, string> =
  {
    scheduled: 'bg-blue-700',
    active: 'bg-success-500',
    complete: 'bg-trip-complete-border',
  };

export function AssignmentStatusBadge({ status }: AssignmentStatusBadgeProp) {
  const statusClassName = assignmentStatusClassNames[status];
  return (
    <div
      className={`${statusClassName} h-3 w-3 rounded-full`}
      data-testid={`assignment-status-badge-${status}`}
      title={`Assignment status: ${status}`}
    />
  );
}
