import {
  PriceSummary,
  TripPriceSummaryRead,
} from 'src/common/external/bambi-api/bambiApi';

import { AdditionalRequirementDetails } from './AdditionalRequirementDetails';
import { AfterHoursFeeDetails } from './AfterHoursFeeDetails';
import { HolidayFeeDetails } from './HolidayFeeDetails';
import { LateCancellationFeeDetails } from './LateCancellationFeeDetails';
import { MileageDetails } from './MileageDetails';
import { NoShowFeeDetails } from './NoShowFeeDetails';
import { StairFeeDetails } from './StairFeeDetails';
import { WaitTimeDetails } from './WaitTimeDetails';

export interface PriceSummaryData {
  sub_total: number;
  minimum_price_cents?: number | null;
  rate?: number;
  miles?: number;
  free_miles?: number;
  minutes?: number;
  free_minutes?: number;
  period?: number;
  cancellation_reason?: string;
  canceled_at?: string;
  scheduled_pickup_at?: string;
  minutes_before_pickup?: number;
  steps?: number;
  minimum_steps?: number;
  fee_type?: string;
  additional_attendants?: number;
  additional_passengers?: number;
  is_bariatric?: boolean;
  is_wheelchair_provided?: boolean;
  is_oxygen_provided?: boolean;
  pickup_time?: string;
  business_hours_start?: string | null;
  business_hours_end?: string | null;
  day_of_week?: string;
  holiday?: string | null;
  date?: string;
  late_cancellation_fee_type?: string;
  no_show_fee_type?: string;
}

export const usePriceSummaryTableData = (
  summary?: TripPriceSummaryRead['price_summary'] | PriceSummary
) => {
  const labelDetailsMap: {
    [key: string]: (data?: PriceSummaryData | null) => any;
  } = {
    'Base Fee': () => <td colSpan={3}></td>,
    'Minimum Trip Price': () => <td colSpan={3}></td>,
    'Loaded Mileage Fee': (data) => <MileageDetails data={data} />,
    'Long Distance Mileage Rate': (data) => <MileageDetails data={data} />,
    'Unloaded Mileage Fee': (data) => <MileageDetails data={data} />,
    'Wait Time Fee': (data) => <WaitTimeDetails data={data} />,
    'No Show Fee': (data) => <NoShowFeeDetails data={data} />,
    'Late Cancellation Fee': (data) => (
      <LateCancellationFeeDetails data={data} />
    ),
    'Pickup Stair Fee': (data) => <StairFeeDetails data={data} />,
    'Dropoff Stair Fee': (data) => <StairFeeDetails data={data} />,
    'Additional Attendants Fee': (data) => (
      <AdditionalRequirementDetails
        label="Attendants"
        property={data?.additional_attendants}
      />
    ),
    'Additional Passengers Fee': (data) => (
      <AdditionalRequirementDetails
        label="Passengers"
        property={data?.additional_passengers}
      />
    ),
    'Bariatric Fee': (data) => (
      <AdditionalRequirementDetails
        label="Bariatric"
        property={data?.is_bariatric ? 'Yes' : 'No'}
      />
    ),
    'Wheelchair Rental Fee': (data) => (
      <AdditionalRequirementDetails
        label="Provided"
        property={data?.is_wheelchair_provided ? 'Yes' : 'No'}
      />
    ),
    'Oxygen Fee': (data) => (
      <AdditionalRequirementDetails
        label="Provided"
        property={data?.is_oxygen_provided ? 'Yes' : 'No'}
      />
    ),
    'After Hours Fee': (data) => <AfterHoursFeeDetails data={data} />,
    'Weekend Fee': (data) => (
      <AdditionalRequirementDetails
        label="Day of Week"
        property={data?.day_of_week}
      />
    ),
    'Holiday Fee': (data) => <HolidayFeeDetails data={data} />,
    'Infectious Disease Fee': () => <td colSpan={3}></td>,
    'Same Day Booking Fee': () => <td colSpan={3}></td>,
  };

  const getLabelDetails = (label: string, data: PriceSummaryData) => {
    const getDetails = labelDetailsMap[label];
    return getDetails(data);
  };

  const feeSummaries: {
    label: string;
    data?: PriceSummaryData | null;
    subTotal?: number;
  }[] = [
    {
      label: 'Base Fee',
      data: summary?.base_fee_summary,
      subTotal: summary?.base_fee_summary?.sub_total,
    },
    {
      label: 'Minimum Trip Price',
      data: {
        minimum_price_cents: summary?.minimum_price_cents,
        sub_total: summary?.minimum_price_cents || 0,
      },
      subTotal:
        typeof summary?.minimum_price_cents === 'number'
          ? summary.minimum_price_cents
          : undefined,
    },
    {
      label: 'Loaded Mileage Fee',
      data: summary?.loaded_mileage_fee_summary,
      subTotal: summary?.loaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Long Distance Mileage Rate',
      data: summary?.long_distance_loaded_mileage_fee_summary,
      subTotal: summary?.long_distance_loaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Unloaded Mileage Fee',
      data: summary?.unloaded_mileage_fee_summary,
      subTotal: summary?.unloaded_mileage_fee_summary?.sub_total,
    },
    {
      label: 'Wait Time Fee',
      data: summary?.wait_time_fee_summary,
      subTotal: summary?.wait_time_fee_summary?.sub_total,
    },
    {
      label: 'No Show Fee',
      data: summary?.no_show_fee_summary,
      subTotal: summary?.no_show_fee_summary?.sub_total,
    },
    {
      label: 'Late Cancellation Fee',
      data: summary?.late_cancellation_fee_summary,
      subTotal: summary?.late_cancellation_fee_summary?.sub_total,
    },
    {
      label: 'Pickup Stair Fee',
      data: summary?.pickup_stair_fee_summary,
      subTotal: summary?.pickup_stair_fee_summary?.sub_total,
    },
    {
      label: 'Dropoff Stair Fee',
      data: summary?.dropoff_stair_fee_summary,
      subTotal: summary?.dropoff_stair_fee_summary?.sub_total,
    },
    {
      label: 'Additional Attendants Fee',
      data: summary?.additional_attendants_fee_summary,
      subTotal: summary?.additional_attendants_fee_summary?.sub_total,
    },
    {
      label: 'Additional Passengers Fee',
      data: summary?.additional_passengers_fee_summary,
      subTotal: summary?.additional_passengers_fee_summary?.sub_total,
    },
    {
      label: 'Bariatric Fee',
      data: summary?.bariatric_fee_summary,
      subTotal: summary?.bariatric_fee_summary?.sub_total,
    },
    {
      label: 'Wheelchair Rental Fee',
      data: summary?.wheelchair_rental_fee_summary,
      subTotal: summary?.wheelchair_rental_fee_summary?.sub_total,
    },
    {
      label: 'Oxygen Fee',
      data: summary?.oxygen_fee_summary,
      subTotal: summary?.oxygen_fee_summary?.sub_total,
    },
    {
      label: 'After Hours Fee',
      data: summary?.after_hours_fee_summary,
      subTotal: summary?.after_hours_fee_summary?.sub_total,
    },
    {
      label: 'Weekend Fee',
      data: summary?.weekend_fee_summary,
      subTotal: summary?.weekend_fee_summary?.sub_total,
    },
    {
      label: 'Holiday Fee',
      // TODO: Types don't match
      // @ts-ignore
      data: summary?.holiday_fee_summary,
      // @ts-ignore
      subTotal: summary?.holiday_fee_summary?.sub_total,
    },
    {
      label: 'Infectious Disease Fee',
      data: summary?.infectious_disease_fee_summary,
      subTotal: summary?.infectious_disease_fee_summary?.sub_total,
    },
    {
      label: 'Same Day Booking Fee',
      data: summary?.same_day_booking_fee_summary,
      subTotal: summary?.same_day_booking_fee_summary?.sub_total,
    },
  ];

  return { feeSummaries, getLabelDetails };
};
