import { Marker } from 'react-map-gl';

import { useAppSelector } from 'src/app/store';
import { MapPin } from 'src/common/BambiMapView/MapPin';
import { SignatureRead } from 'src/common/external/bambi-api/bambiApi';

import { selectCurrentTripSummaryId } from './selectors/selectCurrentTripSummaryId';

export function TripSignatureMarker({
  tripSignature,
  onClick,
}: {
  tripSignature: SignatureRead;
  onClick?: () => void;
}) {
  const selectedTripEventId = useAppSelector(selectCurrentTripSummaryId);
  return (
    <Marker
      latitude={tripSignature.latitude}
      longitude={tripSignature.longitude}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        onClick?.();
      }}
    >
      <MapPin
        isSelected={`signature-${tripSignature.id}` === selectedTripEventId}
      />
    </Marker>
  );
}
