import { RouteObject } from 'react-router-dom';

import { DefaultErrorElement } from 'src/app/DefaultErrorElement';
import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { DriverReportsRoute } from './DriverReportsRoute';
import { PayerReportsRoute } from './PayerReportsRoute';
import { ReportsOverviewRoute } from './ReportsOverviewRoute/ReportsOverviewRoute';

export const reportsRoutes: RouteObject[] = [
  {
    index: true,
    element: <ReportsOverviewRoute />,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: 'drivers',
    element: <DriverReportsRoute />,
  },
  {
    path: 'payers',
    element: <PayerReportsRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/reports" linkText="Go back to reports" />
    ),
  },
];
