import { useMemo } from 'react';

import { useMembersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import { CredentialCategory } from '../types';

export function useMemberOptions(category: CredentialCategory) {
  const { data, isLoading, error } = useMembersListQuery(
    {},
    {
      skip: category === 'Misc' || category === 'Vehicle',
    }
  );

  const options = useMemo<SelectOption[]>(
    () =>
      data?.results.map((member) => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`,
      })) ?? [],
    [data]
  );

  return {
    options,
    isLoading,
    error,
  };
}
