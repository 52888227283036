import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { dispatcherSlice } from '../dispatcher.slice';
import { MultiloadAssignmentSelection } from './MultiloadAssignmentSelection';
import { MultiloadIcon } from './MultiloadIcon';

export function MultiloadSelectionStep({ onNext }: { onNext: () => void }) {
  const dispatch = useAppDispatch();
  const { selectedTripIdsToMultiload, mode } = useAppSelector(
    (state) => state.dispatcher.multiloadAssignmentFlow
  );
  return (
    <div className="flex max-w-md flex-col gap-6 p-2">
      <div className="mt-3 flex justify-center">
        <MultiloadIcon />
      </div>
      {['assign-trip', 'create-assigned-trip-multi-load'].includes(mode) && (
        <>
          <h2 className="-mt-2 text-center text-xl">
            Do you want to multi-load?
          </h2>
        </>
      )}
      <div className="max-h-[50vh] overflow-auto">
        <MultiloadAssignmentSelection />
      </div>
      <div className="flex gap-4">
        <Button
          className="flex-grow"
          onClick={() =>
            dispatch(dispatcherSlice.actions.onMultiloadFlowCancel())
          }
        >
          Cancel
        </Button>
        <Button
          className="flex-grow"
          variant="primary"
          onClick={onNext}
          disabled={
            mode === 'create-assigned-trip-multi-load' &&
            selectedTripIdsToMultiload.length < 2
          }
        >
          {mode === 'assign-trip' &&
            assignTripNextText(selectedTripIdsToMultiload.length)}
          {mode === 'edit-trip-multi-load' &&
            editTripNextText(selectedTripIdsToMultiload.length)}
          {mode === 'create-assigned-trip-multi-load' &&
            'Multi-load with selected trips'}
        </Button>
      </div>
    </div>
  );
}

function assignTripNextText(numSelectedTrips: number) {
  if (numSelectedTrips < 2) {
    return 'Assign without multi-loading';
  }
  return 'Multi-load with selected trips';
}

function editTripNextText(numSelectedTrips: number) {
  if (numSelectedTrips < 2) {
    return 'Unmulti-load these trips';
  }
  return 'Multi-load with selected trips';
}
