import { useEffect, useMemo, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

import { dispatcherSlice } from '../dispatcher.slice';
import { AssignmentHeader } from './AssignmentHeader';
import { VehicleHeader } from './VehicleHeader';

export function DispatchCalendarAssignment({
  assignment,
}: {
  assignment: DispatchAssignmentRead;
}) {
  const ASSIGNMENT_COLUMN_WIDTH_SMALL = 200;
  const assignmentHeaderRef = useRef<any>();
  const assignmentHeaderWidth = assignmentHeaderRef.current?.clientWidth;

  const isWidthSmall =
    assignmentHeaderWidth &&
    assignmentHeaderWidth <= ASSIGNMENT_COLUMN_WIDTH_SMALL;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      dispatcherSlice.actions.setAssignmentColumnIsWidthSmall({
        assignmentId: assignment.id,
        isWidthSmall,
      })
    );
  }, [assignment.id, dispatch, isWidthSmall]);
  const assignmentColumnVisualState = useAppSelector(
    (state) => state.dispatcher.assignmentColumnVisualState
  );
  const showCondensed = useMemo(() => {
    const isCurrentColumnWidthSmall =
      assignmentColumnVisualState[assignment.id]?.isWidthSmall === true;
    return (
      isCurrentColumnWidthSmall ||
      Object.keys(assignmentColumnVisualState).some(
        (key) => assignmentColumnVisualState[key]?.isWidthSmall
      )
    );
  }, [assignmentColumnVisualState, assignment.id]);
  return (
    <div className="flex flex-col gap-2 text-left" ref={assignmentHeaderRef}>
      <VehicleHeader
        type={assignment.vehicle.category}
        name={assignment.vehicle.nickname}
        showCondensed={showCondensed}
      />
      <AssignmentHeader assignment={assignment} showCondensed={showCondensed} />
    </div>
  );
}
